<template>
  <v-card
    width="100%"
    class="flo-pa-card"
  >
    <v-row
      class="ma-0"
      style="background-color: rgba(27, 101, 224, 1); border-radius: 14px; color: white"
    >
      <div style="padding: 3px; margin: 4px; background-color: white; border-radius: 10px">
        <v-img
          :src="organisationsBild(item.tenantId)"
          contain
          height="15"
          width="15"
          min-width="15"
        />
      </div>
      <div
        cols="10"
        style="padding: 2px; font-size: 14px; line-height: 25px; text-overflow: hidden"
      >
        {{ item.type }}
      </div>
    </v-row>
    <v-row
      class="ma-0"
      style="padding: 1px 4px 7px 4px"
    >
      <v-col
        class="pa-0"
        style="width: 58px; min-width: 58px; height: 18px"
      >
        <v-icon
          small
          color="blue"
          class="pr-05"
        >
          mdi-calendar
        </v-icon>
        <span class="small-text pr-0">{{ holeDatum(item) }}</span>
      </v-col>
      <v-col
        v-if="isEintägig(item)"
        class="pa-0 flex-grow-1"
        style="width: 72px; min-width: 72px; height: 18px"
      >
        <v-icon
          small
          color="green"
          class="pr-05"
        >
          mdi-clock-outline
        </v-icon>
        <span class="small-text">{{ item.startTime }}-{{ item.endTime }}</span>
      </v-col>
      <v-col
        v-if="item.title"
        cols="12"
        class="pa-0"
        style="height: 20px"
      >
        <v-icon
          small
          color="red"
        >
          mdi-map-marker
        </v-icon>
        <span class="small-text">{{ item.title }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import formatMixin from '../formatMixin';

export default formatMixin.extend({
  mixins: [formatMixin],
  props: ['item'],
  data: () => ({}),
  methods: {
    holeDatum(item: any) {
      if (item.startDate !== item.endDate) {
        return (
          `${this.datumLeserlichKurz(item.startDate)}-${this.datumLeserlichKurz(item.endDate)}`
        );
      }
      return this.datumLeserlichKurz(item.startDate);
    },
    isEintägig(item: any) {
      return item.startDate === item.endDate;
    },
    getKalendarCol(item: any) {
      return this.isEintägig(item) ? 1 : 12;
    },
    organisationsBild(tenantId: any) {
      // filter direkt aus store anwenden ohne parameterübergabe!?
      const org = this.organisationen.find((el: any) => el.tenantId === tenantId);
      if (org) {
        return org.logoURL;
      }
      return 'http://simpleicon.com/wp-content/uploads/cute.png';
    },
  },
  computed: {
    ...mapGetters([
      // nur für vuex, andere unten mit ...obj
      'organisationen',
    ]),
  },
});
</script>

<style>
.small-text {
  font-size: 10px;
  line-height: 11px;
  display: inline-block;
  overflow-wrap: break-word;
}

.pr-05 {
  padding-right: 2px;
}

.flo-pa-card {
  border-radius: 14px !important;
}
</style>
