<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model.trim="email"
                prepend-icon="person"
                name="email"
                label="Email"
                type="email"
                :rules="emailRules"
                required
              />
              <v-text-field
                id="passwort"
                v-model="passwort"
                prepend-icon="lock"
                name="passwort"
                label="Passwort"
                required
                :rules="passwortRules"
                :append-icon="showPasswort ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPasswort ? 'text' : 'password'"
                @click:append="showPasswort = !showPasswort"
                @keyup.enter="submit"
              />
              <div class="d-flex">
                <a
                  style="margin-left: auto"
                  class="text-caption text-decoration-none text-blue"
                  rel="noopener noreferrer"
                  @keyup.enter="showPasswordReset"
                  @click="showPasswordReset"
                >Passwort vergessen?</a>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :disabled="!valid" @click="submit"> Login </v-btn>
            <v-btn color="secondary" @click="showRegister"> Registrieren </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import EventBus from '../eventBus';

export default Vue.extend({
  name: 'LoginComponent',
  data: () => ({
    valid: false,
    email: '',
    passwort: '',
    emailRules: [(v) => !!v || 'Pflichtfeld', (v) => /.+@.+/.test(v) || 'Ungültige Email'],
    passwortRules: [
      (v) => !!v || 'Pflichtfeld',
      (v) => v.length >= 6 || 'passwort must be greater than 6 characters',
    ],
    showPasswort: false,
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        const data = {
          email: this.email.replace(/ /g, ''),
          password: this.passwort, // muss 'password' wegen passport.js
        };
        this.$store.dispatch('setLoading', { state: true, message: 'Login' });
        try {
          console.log(getAuth());
          const user = await signInWithEmailAndPassword(getAuth(), data.email, data.password);
          console.log(user);
          this.$store.dispatch('userLogin', { userId: user.user.uid });
          this.$store.dispatch('setLoading', { state: false });
          this.$store.dispatch('fetchOperatingData');
        } catch (error) {
          this.$store.dispatch('setLoading', { state: false });
          console.error(JSON.stringify(error));

          if (error.response?.status === 204) {
            // cors pre request abfangen (es werden 2 gesendent), bessere lösung?
          } else if (error.code) {
            EventBus.$emit('showSnackbar', error.message);
          } else {
            console.log(error.response);
            EventBus.$emit('showSnackbar', 'Unbekannter Fehler');
          }
        }
      }
    },
    showRegister() {
      EventBus.$emit('showRegister');
    },
    showPasswordReset() {
      EventBus.$emit('showPasswordReset');
    },
  },
});
</script>
