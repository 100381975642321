<template>
  <v-dialog
    v-if="showPasswortDialog"
    v-model="showPasswortDialog"
    width="500"
    fullscreen
    scrollable
    style="height: 100%"
  >
    <v-card>
      <v-card-title>Passwort ändern</v-card-title>
      <v-divider />
      <v-container style="overflow-y: scroll">
        <v-text-field
          v-model="formData.passwortAlt"
          label="Passwort Alt"
          outlined
          :rules="[rules.required, rules.passwort]"
          :append-icon="showAltesPasswort ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showAltesPasswort ? 'text' : 'password'"
          @click:append="showAltesPasswort = !showAltesPasswort"
        />
        <v-text-field
          v-model="formData.passwortNeu"
          label="Passwort Neu"
          outlined
          :rules="[rules.required, rules.passwort]"
          :append-icon="showNeuesPasswort ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNeuesPasswort ? 'text' : 'password'"
          @click:append="showNeuesPasswort = !showNeuesPasswort"
        />
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          :disabled="!isFormValid"
          text
          @click="savePasswort()"
        >
          Ändern
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="showPasswortDialog = false"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import api from '../../api';

export default Vue.extend({
  data: () => ({
    showPasswortDialog: false,
    showAltesPasswort: false,
    showNeuesPasswort: false,
    formData: {
      passwortAlt: null,
      passwortNeu: null,
    },
    rules: {
      email: (v) => /.+@.+/.test(v) || 'Ungültige Email',
      required: (v) => !!v || 'Pflichtfeld',
      passwort: (v) => (v && v.length >= 6) || 'Passwort muss mindestens 6 Zeichen lang sein',
    },
  }),
  computed: {
    isFormValid() {
      return (
        this.formData
        && this.formData.passwortAlt
        && this.formData.passwortNeu
        && this.formData.passwortNeu.length > 6
      );
    },
  },
  mounted() {
    EventBus.$on('showPasswortDialog', (payload) => {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.showPasswortDialog = true;
    });
  },
  methods: {
    savePasswort() {
      this.$store.dispatch('setLoading', { state: true, message: 'Sende Daten' });
      api
        .postPasswort(this.formData)
        .then((response) => {
          this.showPasswortDialog = false;
          EventBus.$emit('showSnackbar', 'Passwort geändert');
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
  },
});
</script>
