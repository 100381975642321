<template>
  <div>
    <scroller
      :on-refresh="refreshJobData"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-card flat color="basil">
        <v-btn
          class="my-4"
          :disabled="teilnehmerMaximumErreicht() || !isJobAbschliessbar"
          @click="showTeilnehmerHinzufuegenSucheDialog"
        >
          + Teilnehmer Hinzufügen
        </v-btn>
        <v-expansion-panels>
          <v-expansion-panel v-for="anmeldung in anmeldungenSortiert" :key="anmeldung.uuid">
            <v-expansion-panel-header
              :disable-icon-rotate="isAnwesend(anmeldung.uuid)" class="pa-2">
              <v-img
                :src="teilnehmerBildURL(anmeldung)"
                :lazy-src="defaultProfileUrl"
                style="max-width: 32px"
                class="mr-1"
              />
              {{ anmeldung.vorname }} {{ anmeldung.nachname }}, {{ anmeldung.alter }}
              <div v-if="darfNichtAlleinNachHause(anmeldung)" class="ml-5">🚫</div>
              <div v-if="zahlungseingangExistiert(anmeldung.uuid)" class="ml-5">
                <currency-input
                  disabled
                  style="color: green; max-width: 50px"
                  :value="zahlungseingang(anmeldung.uuid)"
                  currency="EUR"
                  locale="de"
                />
              </div>
              <template #actions>
                <v-icon :color="getAnwesenheitIconColor(anmeldung.uuid)">
                  {{ getAnwesenheitIcon(anmeldung.uuid) }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-img
                :src="teilnehmerBildURL(anmeldung)"
                :lazy-src="defaultProfileUrl"
                style="max-width: 120px"
              />
              <div>
                <v-chip
                  v-for="quali in qualifikationenAsArray(anmeldung)"
                  :key="quali"
                  class="mr-1"
                >
                  {{ getQualiName(quali) }}
                </v-chip>
              </div>
              <div>
                <v-chip
                  v-for="quali in hinzugefuegteQualifikationen(anmeldung.uuid)"
                  :key="quali"
                  :disabled="!isJobAbschliessbar"
                  class="mr-1"
                  close
                  @click:close="loescheQualifikation(anmeldung.uuid, quali)"
                >
                  {{ getQualiName(quali) }}
                </v-chip>
              </div>
              <div v-if="teilnehmerZahltBar(anmeldung)">
                Zu zahlen:
                <v-icon color="dark-green"> mdi-currency-eur </v-icon>
                <span>{{ numberToGerman(anmeldung.betrag) }}</span>
              </div>
              <div v-if="darfNichtAlleinNachHause(anmeldung)">
                <v-chip
                  class="mr-1"
                >
                  darf <b>nicht</b> allein nach Hause
                </v-chip>
              </div>
              <v-row>
                <v-col>
                  <v-btn @click="callPhoneNumber(anmeldung)">
                        <v-icon>mdi-phone</v-icon>
                        Notfallkontakt
                      </v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                        v-if="!isAnwesend(anmeldung.uuid)"
                        :disabled="!isJobAbschliessbar"
                        @click="setAnwesenheit(anmeldung.uuid, true)"
                      >
                        anmelden
                      </v-btn>
                      <v-btn
                        v-if="isAnwesend(anmeldung.uuid)"
                        :disabled="!isJobAbschliessbar"
                        @click="setAnwesenheit(anmeldung.uuid, false)"
                      >
                        abmelden
                      </v-btn>
                </v-col>
              </v-row>
              <v-btn
                v-if="teilnehmerZahltBar(anmeldung)"
                :disabled="!isJobAbschliessbar"
                @click="showZahlungseingangDialog(anmeldung)"
              >
                Zahlungseingang
              </v-btn>
              <v-btn
                v-if="hatErwerbbareQualifikationen(anmeldung)"
                :disabled="!isJobAbschliessbar"
                @click="showQualifikationenDialog(anmeldung)"
              >
                Qualifikationen
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </scroller>

    <v-dialog
      v-model="zahlungseingangDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Zahlungseingang </v-card-title>
        <v-card-text>
          <v-currency-field v-model="zahlungseingangDialogBetrag" label="Betrag" suffix="€" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="zahlungseingangDialog = false"> Abbrechen </v-btn>
          <v-btn color="primary" text @click="zahlungseingangSuccess"> Bestätigen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="qualifikationenDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Qualifikationen </v-card-title>
        <v-card-text>
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="ausgewaehlteQualifikation"
                  :items="auswaehlbareQualifkationen"
                  item-value="id"
                  item-text="name"
                  label="Qualifikation"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="qualifikationenDialog = false"> Abbrechen </v-btn>
          <v-btn color="primary" text @click="qualifikationHinzufuegen"> Hinzufügen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="teilnehmerHinzufuegenSucheDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Teiln. Hinzufügen </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="searchInput"
            label="Suche"
            outlined
            :append-icon="'search'"
            :rules="searchInputRules"
            @click:append="getSearchResults"
            @keyup.enter="getSearchResults"
          />
          <v-list-item-group style="max-height: 280px; overflow-y: scroll">
            <v-list-item
              v-for="mitglied in searchResults"
              :key="mitglied.id"
              @click="searchResultsActiveItem = mitglied"
            >
              {{ alsSuchergebnisListenelementText(mitglied) }}
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!searchResultsActiveItem"
            text
            @click="holeTeilnehmerAnmeldungVorlage"
          >
            Hole Anmeldedaten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="teilnehmerHinzufuegenDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card v-if="teilnehmerHinzufuegenDialog">
        <v-card-title class="headline"> Teilnehmer Hinzufügen </v-card-title>
        <v-card-text  v-if="teilnehmerAnmeldungVorlage">
          <v-text-field
            v-model="teilnehmerAnmeldungVorlage.mitglied.vorname"
            disabled
            label="Vorname"
            readonly
          />
          <v-text-field
            v-model="teilnehmerAnmeldungVorlage.mitglied.nachname"
            disabled
            label="Nachname"
            readonly
          />
          <v-text-field
            v-model="teilnehmerAnmeldungVorlage.mitglied.id"
            disabled
            label="Mitglied ID"
            readonly
          />
          <v-text-field
            v-model="teilnehmerAnmeldungVorlage.mitglied.email"
            disabled
            label="E-Mail"
            readonly
          />
           <v-text-field
            v-model="teilnehmerAnmeldungVorlage.mitglied.alter"
            disabled
            label="Alter"
            readonly
          />
           <v-text-field
            v-model="teilnehmerAnmeldungVorlage.mitglied.geschlecht"
            disabled
            label="Geschlecht"
            readonly
          />

          <!-- <v-chip size="x-small">
            {{teilnehmerAnmeldungVorlage.mitglied.alter}} Jahre
          </v-chip >
          <v-chip size="x-small">
            {{teilnehmerAnmeldungVorlage.mitglied.geschlecht}}
          </v-chip >
          <v-chip size="x-small">
            {{teilnehmerAnmeldungVorlage.mitglied.email}}
          </v-chip> -->
          <v-checkbox
            v-model="teilnehmerAnmeldungVorlage.eve"
            label="Darf allein nach Hause"
          />
          <v-textarea
            v-model="teilnehmerAnmeldungVorlage.info"
            label="Info"
            outlined
            rows="1"
            auto-grow
          />
          <v-checkbox
            v-model="teilnehmerAnmeldungVorlage.mitarbeiterBestätigung"
            aria-required>
            <template v-slot:label>
              <span style="font-size: 12px">
                Ich <b>bestätige</b> den richtigen Kunden ausgewählt zu haben und mir ist bewusst
                dass <b>ggfs. dem Kunden Kosten anfallen</b>
                können und ich die Buchung <b>nicht rückgängig</b> machen kann!
              </span>
            </template>
          </v-checkbox>
          <!-- <v-currency-field
            v-if="!isTeilnehmerAnmeldungKostenlos()"
            v-model="teilnehmerAnmeldungVorlage.preis"
            disabled
            label="Betrag"
            suffix="€"
          />
          <v-select
            v-if="!isTeilnehmerAnmeldungKostenlos()"
            v-model="ausgewaehlteZahlungsart"
            :items="auswaehlbareZahlungsarten"
            label="Zahlungsart"
            required
          /> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="teilnehmerHinzufuegenDialog = false">
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!hatMitarbeiterTeilnehmerHinzufügenBestätigt"
            @click="teilnehmerHinzufuegen"
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import api from '@/api';
import Config from '../config';
import formatMixin from '../formatMixin';

export default formatMixin.extend({
  mixins: [formatMixin],
  props: ['job'],
  data: (): {answesenheitBestaetigenDialog: boolean,
    answesenheitRueckzugDialog: boolean,
    zahlungseingangDialog: boolean,
    qualifikationenDialog: boolean,
    auswaehlbareQualifkationen: any,
    ausgewaehlteQualifikation: null,
    zahlungseingangDialogBetrag: 0,
    teilnehmerHinzufuegenSucheDialog: boolean,
    teilnehmerHinzufuegenDialog: boolean,
    teilnehmerAnmeldungVorlage: any,
    auswaehlbareZahlungsarten: any[],
    ausgewaehlteZahlungsart: any,
    currentAnmeldung: any,
    value: number,
    searchInput: string,
    searchResults: any[],
    searchResultsActiveItem: any,
    defaultProfileUrl: string,
    searchInputRules: ((input: any) => any)[] } => ({
    answesenheitBestaetigenDialog: false,
    answesenheitRueckzugDialog: false,
    zahlungseingangDialog: false,
    qualifikationenDialog: false,
    auswaehlbareQualifkationen: {},
    ausgewaehlteQualifikation: null,
    zahlungseingangDialogBetrag: 0,
    teilnehmerHinzufuegenSucheDialog: false,
    teilnehmerHinzufuegenDialog: false,
    teilnehmerAnmeldungVorlage: null,
    auswaehlbareZahlungsarten: [],
    ausgewaehlteZahlungsart: null,
    currentAnmeldung: {},
    value: 10,
    searchInput: '',
    searchResults: [],
    searchResultsActiveItem: null,
    defaultProfileUrl: Config.DEFAULT_PROFILE_URL,
    searchInputRules: [
      (input: any) => (input && input.length && input.length > 2) || 'Mindestens 3 Zeichen',

    ],
  }),
  // methods: {
  //   fun1() {
  //     this.fun2();
  //   },
  //   fun2() {
  //     console.log('fun2');
  //   },
  // },
  methods: {
    swipedHandler(anmeldungId: string): any {
      const docId = this.job.uuid;
      const anwesenheitStatus = !this.isAnwesend(anmeldungId);
      this.$store.dispatch('syncStore/setTeilnehmerAnwesenheit', {
        docId,
        anmeldungId,
        anwesend: anwesenheitStatus,
      });
    },
    touchHoldHandler(anmeldungId: string): any {
      console.log(`touchHoldHandler${anmeldungId}`);
      // const docId = this.job.uuid
      // const anwesenheitStatus = !this.isAnwesend(anmeldungId)
      // this.$store.dispatch('syncStore/setTeilnehmerAnwesenheit',
      // { docId, anmeldungId, anwesend: anwesenheitStatus })
    },
    refreshJobData(done: () => void): any {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Job Daten Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    teilnehmerMaximumErreicht(): any {
      return false; // this.job.anmeldungen.length >= this.job.teilnehmerMaximum;
    },
    getSearchResults(): any {
      if (this.searchInputRules[0](this.searchInput) !== true) {
        return;
      }

      const { uuid } = this.job;
      const { searchInput } = this;

      this.$store.dispatch('setLoading', { state: true, message: 'Suche' });
      api
        .getMitgliederSuche(uuid, searchInput)
        .then((response) => {
          const { data } = response;
          this.searchResults = data;
          this.searchResultsActiveItem = null;
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    showAnwesendDialog(anmeldung: any): any {
      this.currentAnmeldung = anmeldung;
      this.answesenheitBestaetigenDialog = true;
    },
    showAbwesendDialog(anmeldung: any): any {
      this.currentAnmeldung = anmeldung;
      this.answesenheitRueckzugDialog = true;
    },
    showZahlungseingangDialog(anmeldung: any): any {
      this.currentAnmeldung = anmeldung;
      if (this.zahlungseingangExistiert(anmeldung.uuid)) {
        const betrag = this.zahlungseingang(anmeldung.uuid);
        this.zahlungseingangDialogBetrag = betrag;
      } else {
        this.zahlungseingangDialogBetrag = anmeldung.betrag;
      }
      this.zahlungseingangDialog = true;
    },
    showQualifikationenDialog(anmeldung: any): any {
      this.currentAnmeldung = anmeldung;
      this.auswaehlbareQualifkationen = this.erwerbbareQualifikationen(anmeldung);
      this.ausgewaehlteQualifikation = null;
      this.qualifikationenDialog = true;
    },
    showTeilnehmerHinzufuegenSucheDialog(): any {
      this.searchInput = '';
      this.searchResults = [];
      this.teilnehmerHinzufuegenSucheDialog = true;
      this.searchResultsActiveItem = null;
    },
    zahlungseingangSuccess(): any {
      const docId = this.job.uuid;
      const betrag = this.zahlungseingangDialogBetrag;
      const anmeldungId = this.currentAnmeldung.uuid;
      this.$store.dispatch('syncStore/setTeilnehmerZahlungseingang', {
        docId,
        anmeldungId,
        betrag,
      });
      this.zahlungseingangDialog = false;
    },
    // anwesendDialogSuccess() {
    //   this.setAnwesenheit(true);
    //   this.answesenheitBestaetigenDialog = false;
    // },
    // abwesendDialogSuccess() {
    //   this.setAnwesenheit(false);
    //   this.answesenheitRueckzugDialog = false;
    // },
    setAnwesenheit(anmeldungId: string, status: any): any {
      const docId = this.job.uuid;
      this.$store.dispatch('syncStore/setTeilnehmerAnwesenheit', {
        docId,
        anmeldungId,
        anwesend: status,
      });
    },
    isAnwesend(anmeldungId: string): any {
      if (!this.getJobDoc) {
        return false;
      }
      if (!this.getJobDoc.teilnehmer) {
        return false;
      }
      if (!this.getJobDoc.teilnehmer[anmeldungId]) {
        return false;
      }
      return this.getJobDoc.teilnehmer[anmeldungId].anwesend;
    },
    zahlungseingangExistiert(anmeldungId: string): any {
      if (!this.getJobDoc) {
        return false;
      }
      if (!this.getJobDoc.teilnehmer) {
        return false;
      }
      if (!this.getJobDoc.teilnehmer[anmeldungId]) {
        return false;
      }
      if (!this.getJobDoc.teilnehmer[anmeldungId].betrag) {
        return false;
      }
      return true;
    },
    zahlungseingang(anmeldungId: string): any {
      if (this.zahlungseingangExistiert(anmeldungId)) {
        return this.getJobDoc.teilnehmer[anmeldungId].betrag;
      }
      return null;
    },
    hinzugefuegteQualifikationen(anmeldungId: string): any {
      if (!this.getJobDoc) {
        return [];
      }
      if (!this.getJobDoc.teilnehmer) {
        return [];
      }
      if (!this.getJobDoc.teilnehmer[anmeldungId]) {
        return [];
      }
      if (!this.getJobDoc.teilnehmer[anmeldungId].qualifikationen) {
        return [];
      }
      return this.getJobDoc.teilnehmer[anmeldungId].qualifikationen;
    },
    zahlungseingangLeserlich(anmeldungId: string): any {
      // todo parse readable currency
      return this.zahlungseingang(anmeldungId);
    },
    getAnwesenheitIcon(anmeldungId: string): any {
      return this.isAnwesend(anmeldungId) ? 'check' : 'mdi-menu-down';
    },
    getAnwesenheitIconColor(anmeldungId: string): any {
      return this.isAnwesend(anmeldungId) ? 'green' : 'black';
    },
    getQualiName(qualiId: any): string {
      const result = this.alleQualifikationen.find((el: any) => String(el.id) === qualiId);
      if (result) {
        return result.name;
      }
      return 'Fehler';
    },
    qualifikationenAsArray(anmeldung: any): any {
      if (anmeldung && anmeldung.qualifikationen) {
        const asArray = anmeldung.qualifikationen.split(',');
        return asArray.map((el: any) => Number.parseInt(el, 10));
      }
      return [];
    },
    erwerbbareQualifikationen(anmeldung: any): any {
      return this.alleQualifikationen.filter((el) => {
        if (this.qualifikationenAsArray(anmeldung).indexOf(el.id) === -1) {
          return this.hinzugefuegteQualifikationen(anmeldung.uuid).indexOf(el.id) === -1;
        }
        return false;
      });
    },
    hatErwerbbareQualifikationen(anmeldung: any): any {
      return this.erwerbbareQualifikationen(anmeldung).length;
    },
    qualifikationHinzufuegen(): any {
      const anmeldungId = (this.currentAnmeldung as any).id;
      const qualifikationId = this.ausgewaehlteQualifikation;
      const docId = this.job.uuid;
      this.$store.dispatch('syncStore/addTeilnehmerQualifikation', {
        docId,
        anmeldungId,
        qualifikationId,
      });
      this.qualifikationenDialog = false;
    },
    loescheQualifikation(anmeldungId: string, qualifikationId: any): any {
      const docId = this.job.uuid;
      this.$store.dispatch('syncStore/deleteTeilnehmerQualifikation', {
        docId,
        anmeldungId,
        qualifikationId,
      });
    },
    teilnehmerZahltBar(anmeldung: any): any {
      return anmeldung.zahlungsart && anmeldung.zahlungsart === 'Bar' && anmeldung.betrag > 0;
    },
    teilnehmerBildURL(anmeldung: any): any {
      if (anmeldung.bildFileId) {
        return `http://drive.google.com/uc?export=view&id=${anmeldung.bildFileId}`;
      }
      return this.defaultProfileUrl;
    },
    holeTeilnehmerAnmeldungVorlage(): any {
      const { uuid } = this.job;
      // const mitgliedId = (this.searchResultsActiveItem as any).id;

      this.teilnehmerAnmeldungVorlage = {
        mitglied: this.searchResultsActiveItem,
        eve: false,
        info: '',
        mitarbeiterBestätigung: false,
      };
      this.teilnehmerHinzufuegenSucheDialog = false;
      this.teilnehmerHinzufuegenDialog = true;

      // this.$store.dispatch('setLoading', { state: true, message: 'Hole Anmeldedaten' });
      // api
      //   .getMitgliedAnmeldungVorlage(uuid, mitgliedId)
      //   .then((response) => {
      //     const { data } = response;
      //     this.teilnehmerAnmeldungVorlage = data;
      //     // this.auswaehlbareZahlungsarten = data.zahlungsarten;
      //     // this.ausgewaehlteZahlungsart = null;
      //     this.teilnehmerHinzufuegenSucheDialog = false;
      //     this.teilnehmerHinzufuegenDialog = true;
      //   })
      //   .catch((error) => {
      //     if (error && error.response) {
      //       console.log(error.response);
      //       EventBus.$emit('showSnackbar', error.response.data.message);
      //     }
      //   })
      //   .finally(() => {
      //     this.$store.dispatch('setLoading', { state: false });
      //   });
    },
    isTeilnehmerAnmeldungKostenlos(): any {
      return !((this.teilnehmerAnmeldungVorlage as any).preis);
    },
    teilnehmerHinzufuegen(): any {
      this.teilnehmerHinzufuegenDialog = false;
      const { uuid } = this.job;
      const { mitglied, eve, info } = this.teilnehmerAnmeldungVorlage;
      // const zahlungsart = this.ausgewaehlteZahlungsart;
      // const { preis } = this.teilnehmerAnmeldungVorlage ?? { preis: null };
      // const isTrainingFestgelegt = false; // wtf is this? <-------------

      const notfallkontakt = '';

      const postData = {
        jobUuid: uuid, mitgliedId: mitglied.id, eve, info, notfallkontakt,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Erstelle Anmeldung' });
      api
        .postMitgliedAnmeldung(postData)
        .then(({ data }) => {
          this.$store
            .dispatch('syncJobData', { uuid })
            .then(() => {
              this.setAnwesenheit((data as any).uuid, false);
              EventBus.$emit('showSnackbar', 'Teilnehmer Hinzugefügt');
            })
            .catch((error) => {
              if (error && error.response) {
                EventBus.$emit('showSnackbar', error);
              }
            })
            .finally(() => {
              this.teilnehmerHinzufuegenDialog = false;
            });
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    alsSuchergebnisListenelementText(mitglied: any): string {
      const aktivZeichen = mitglied.vertragaktiv ? 'Mitglied' : 'DropIn';
      const text = `${mitglied.vorname} ${mitglied.nachname} (${mitglied.id}) ${aktivZeichen}`;
      return text;
    },
    darfNichtAlleinNachHause(anmeldung: any): boolean {
      return !anmeldung.darfAlleinNachHause;
    },
    callPhoneNumber(anmeldung: any) {
      const phoneNumber = anmeldung.notfallkontakt;
      window.location.href = `tel:${phoneNumber}`;
    },
  },
  computed: {
    ...mapGetters([
      // nur für vuex, andere unten mit ...obj
      'mitgliedQualifikationen',
    ]),
    ...mapGetters({
      getJobDoc: 'syncStore/getJobDoc',
    }),
    alleQualifikationen(): any[] {
      if (this.mitgliedQualifikationen.length === 0) {
        return [];
      }
      const result = this.mitgliedQualifikationen.find(
        (el: any) => String(el.tenantId) === this.job.tenantId,
      );
      if (result) {
        return result.qualifikationen;
      }
      return [];
    },
    anmeldungenSortiert(): any[] {
      if (!this.job.anmeldungen) {
        return [];
      }
      return this.job.anmeldungen.slice().sort((a: any, b: any) => {
        const aName = a.vorname.toUpperCase();
        const bName = b.vorname.toUpperCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      });
    },
    isJobAbschliessbar(): boolean {
      return this.job.status === 'Vergeben';
    },
    hatMitarbeiterTeilnehmerHinzufügenBestätigt(): boolean {
      return this.teilnehmerAnmeldungVorlage?.mitarbeiterBestätigung;
    },
  },
});
</script>

<style>
.currency-input {
  border-bottom: rgba(0, 0, 0, 0.87) thin solid;
  width: 100%;
  font-size: 24px;
}

.mr-1 b {
  padding: 0 3px;
}

.v-chip {
  margin-bottom: 16px;
}

.text-right {
  text-align: right;
}

</style>
