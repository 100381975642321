<template>
  <div>
    <scroller
      :on-refresh="refresh"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-container>
        <div class="mb-2">
          <h3>Archiv</h3>
        </div>
        <v-row>
          <v-col
            v-for="item in erledigteSortiert"
            :key="item.id"
            style="min-width: 146px; max-width: 219px"
            class="pa-1 flex-grow-1 flex-shrink-0"
            @click="showJob(item)"
          >
            <job-list-element :item="item" />
          </v-col>
        </v-row>
      </v-container>
    </scroller>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import JobListElement from '@/components/JobListElement.vue';
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';

export default Vue.extend({
  components: {
    'job-list-element': JobListElement,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['erledigte', 'jobangeboteJob']),
    erledigteSortiert(): any[] {
      return this.erledigte.slice().sort((a: any, b: any) => {
        const dateA = new Date(a.startDatum).getTime();
        const dateB = new Date(b.startDatum).getTime();
        return dateB - dateA;
      });
    },
  },
  methods: {
    showJob(item: any) {
      // const { uuid } = item;
      // const path = '/archiv/jobansicht';
      // this.$store.dispatch('setMeineJobsJobansichtJobUID', { uuid });
      // EventBus.$emit('routeToJobansicht', { uuid, path });
    },
    refresh(done: () => void) {
      this.$store
        .dispatch('syncErledigte')
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
  },
});
</script>

<style></style>
