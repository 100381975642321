import api from './axios';

const JOB_URL = '/job';
const JOB_BEWERBUNG_URL = 'job/bewerbung';
const JOB_BEWERBUNG_BEENDEN_URL = '/job/bewerbung/beenden';
const JOB_ABSAGEN_URL = '/job/absagen';
const JOB_ABSCHLIESSEN_URL = '/job/abschliessen';
// const AUTHORIZATION_URL = '/user/authorization';
const STAMMDATEN_URL = '/mitarbeiter/stammdaten';
const SETTINGS_URL = '/mitarbeiter/settings';
const ORGANISATIONEN_URL = '/mitarbeiter/organisationen';
const ORGANISATIONEN_BEWERBBAR_URL = '/mitarbeiter/organisationen/bewerbbar';
const ORGANISATIONEN_BEWERBUNGEN_URL = '/mitarbeiter/organisationen/bewerbungen';
const ANSTELLUNGEN_URL = '/mitarbeiter/organisationen/anstellungen';
const JOBTYPEN_URL = '/jobs/typen';
const AUSSCHREIBUNGEN_URL = '/jobs/ausschreibungen';
const BEWERBUNGEN_URL = '/jobs/bewerbungen';
const ZUWEISUNGEN_URL = '/mitarbeiter/zuweisungen';
const ERLEDIGTE_URL = '/mitarbeiter/erledigte';
const MITGLIED_QUALIFIKATIONEN_URL = '/mitarbeiter/organisationen/mitglieder/qualifikationen';
const ARBEITSZEIT_URL = '/job/arbeitszeit';
const ARBEITSZEITEN_URL = '/jobs/arbeitszeiten';
const ORGANISATION_BEWERBUNG_URL = '/mitarbeiter/organisation/bewerbung';
const PASSWORT_URL = '/passwort';
const MITGLIEDER_SUCHE_URL = '/mitglieder/suche';
const MITLGIEDER_ANMELDUNG_VORLAGE_URL = '/mitglieder/anmeldevorlage';
const MITGLIEDER_ANMELDUNG_URL = '/mitglieder/anmeldung';
const LOGIN_URL = '/login';
const ERSATZSUCHE_URL = '/job/ersatzsuche';
const ERSATZSUCHE_BEENDEN_URL = '/job/ersatzsuche/beenden';
const REGISTRIERUNG_URL = '/registrierung';
const COUCHDB_ACCESS_TOKEN_URL = '/couchdb-access-token';

export default {
  getJobData: (uuid: string) => {
    const URL = `${JOB_URL}/${uuid}`;
    return api.get(URL);
  },
  getCouchDBAccessToken: () => api.get(COUCHDB_ACCESS_TOKEN_URL),
  // getAuthorization: () => api.get(AUTHORIZATION_URL),
  getStammdaten: () => api.get(STAMMDATEN_URL),
  getSettings: () => api.get(SETTINGS_URL),
  getOrganisationen: () => api.get(ORGANISATIONEN_URL),
  getOrganisationenBewerbbar: () => api.get(ORGANISATIONEN_BEWERBBAR_URL),
  getOrganisationenBewerbungen: () => api.get(ORGANISATIONEN_BEWERBUNGEN_URL),
  getAnstellungen: () => api.get(ANSTELLUNGEN_URL),
  getJobtypen: () => api.get(JOBTYPEN_URL),
  getAusschreibungen: () => api.get(AUSSCHREIBUNGEN_URL),
  getBewerbungen: () => api.get(BEWERBUNGEN_URL),
  getZuweisungen: () => api.get(ZUWEISUNGEN_URL),
  getErledigte: () => api.get(ERLEDIGTE_URL),
  getArbeitszeiten: () => api.get(ARBEITSZEITEN_URL),
  getMitgliedQualifikationen: () => api.get(MITGLIED_QUALIFIKATIONEN_URL),
  postArbeitszeit: (arbeitszeit: any) => api.post(ARBEITSZEIT_URL, arbeitszeit),
  postStammdaten: (stammdaten: any) => api.post(STAMMDATEN_URL, stammdaten),
  postOrganisationBewerbung: (bewerbung: any) => api.post(ORGANISATION_BEWERBUNG_URL, bewerbung),
  postJobAbsage: (absage: any) => api.post(JOB_ABSAGEN_URL, absage),
  postSettings: (settings: any) => api.post(SETTINGS_URL, settings),
  postPasswort: (formData: any) => api.post(PASSWORT_URL, formData),
  postJobBewerbung: (bewerbung: any) => api.post(JOB_BEWERBUNG_URL, bewerbung),
  postJobAbschliessen: (abschluss: any) => api.post(JOB_ABSCHLIESSEN_URL, abschluss),
  // eslint-disable-next-line max-len
  getMitgliederSuche: (uuid: string, searchInput: string) => api.get(MITGLIEDER_SUCHE_URL, { params: { jobUuid: uuid, suche: searchInput } }),
  getMitgliedAnmeldungVorlage: (uuid: string, mitgliedId: string) => {
    const URL = `${MITLGIEDER_ANMELDUNG_VORLAGE_URL}?uuid=${uuid}&mitgliedId=${mitgliedId}`;
    return api.get(URL);
  },
  postMitgliedAnmeldung: (anmeldung: any) => api.post(MITGLIEDER_ANMELDUNG_URL, anmeldung),
  postLogin: (data: any) => api.post(LOGIN_URL, data),
  postErsatzsuche: (data: any) => api.post(ERSATZSUCHE_URL, data),
  postErsatzsucheBeenden: (data: any) => api.post(ERSATZSUCHE_BEENDEN_URL, data),
  postJobBewerbungBeenden: (bewerbung: any) => api.post(JOB_BEWERBUNG_BEENDEN_URL, bewerbung),
  postRegistrierung: (registrierung: any) => api.post(REGISTRIERUNG_URL, registrierung),
};
