<template>
  <v-dialog
    v-model="showFilterDialog"
    width="500"
    fullscreen
    scrollable
  >
    <v-card>
      <v-card-title>Filter</v-card-title>
      <v-divider />
      <v-list
        flat
        subheader
        style="overflow-y: scroll"
      >
        <v-subheader>Organisationen</v-subheader>

        <v-list-item
          v-for="org in filterbareOrganisationenLocal"
          :key="org.tenantId"
        >
          <v-list-item-title>{{ holeOrganisationName(org.tenantId) }}</v-list-item-title>
          <v-list-item-action>
            <v-checkbox v-model="org.anzeigen" />
          </v-list-item-action>
        </v-list-item>

        <v-subheader>Job Typen</v-subheader>

        <v-list-item
          v-for="jobTyp in filterbareJobTypenLocal"
          :key="jobTyp.jobTyp"
          disabled
        >
          <v-list-item-title>{{ jobTyp.jobTyp }}</v-list-item-title>
          <v-list-item-action>
            <v-checkbox v-model="jobTyp.anzeigen" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="saveFilterSettings()"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import EventBus from '../eventBus';

export default Vue.extend({
  data: () => ({
    showFilterDialog: false,
    filterbareOrganisationenLocal: [] as any[],
    filterbareJobTypenLocal: [] as any[],
    changes: false,
  }),
  computed: {
    filterbareOrganisationen(): any {
      if (this.$store.getters.settings && this.$store.getters.settings.filter) {
        return this.$store.getters.settings.filter.organisationen.slice();
      }
      return [];
    },
    filterbareJobTypen(): any {
      if (this.$store.getters.settings && this.$store.getters.settings.filter) {
        return this.$store.getters.settings.filter.jobTypen.slice();
      }
      return [];
    },
    organisationen(): any {
      return this.$store.getters.organisationen;
    },
  },
  mounted() {
    EventBus.$on('saveFilterSettings', this.saveFilterSettings);
    EventBus.$on('showFilterDialog', () => {
      this.filterbareOrganisationenLocal = this.filterbareOrganisationen.slice();
      this.filterbareJobTypenLocal = this.filterbareJobTypen.slice();
      this.showFilterDialog = true;
    });
  },
  beforeDestroy() {
    EventBus.$off('saveFilterSettings', this.saveFilterSettings);
  },
  methods: {
    holeOrganisationName(tenantId: string) {
      if (!this.organisationen) {
        return 'Unbekannt';
      }
      const org = this.organisationen.find((el: any) => el.tenantId === tenantId);
      return org ? org.name : 'Unbekannt';
    },
    checkboxChanged(state: any) {
      console.log(state);
    },
    saveFilterSettings() {
      const payload = {
        organisationen: this.filterbareOrganisationenLocal,
        jobTypen: this.filterbareJobTypenLocal,
      };
      this.$store.dispatch('setLoading', { state: true, message: 'Speichern' });
      this.$store
        .dispatch('setSettingsFilter', payload)
        .then()
        .catch(() => {
          // if (error && error.response) {
          EventBus.$emit('showSnackbar', 'Filter wurde nur lokal gespeichert');
          // }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
          this.showFilterDialog = false;
        });
    },
  },
});
</script>

<style></style>
