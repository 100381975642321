<template>
  <div>
    <scroller
      :on-refresh="refresh"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-container style="position: relative">
        <div
          style="
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            position: absolute;
            top: 50px;
            z-index: 2;
          "
        />
        <div class="mb-2 d-flex">
          <h3>Stammdaten</h3>
          <v-btn
            class="ml-auto"
            color="#eff2f9"
            @click="showStammdatenDialog()"
          >
            Bearbeiten
          </v-btn>
        </div>
        <v-text-field
          label="Email"
          :value="stammdaten.email"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Vorname"
          :value="stammdaten.vorname"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Nachanme"
          :value="stammdaten.nachname"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Geburtsdatum"
          :value="datumLeserlich(stammdaten.geburtsdatum)"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Geschlecht"
          :value="stammdaten.geschlecht"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Straße & Hausnummer"
          :value="stammdaten.strasseHausnummer"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Postleitzahl"
          :value="stammdaten.postleitzahl"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Ort"
          :value="stammdaten.ort"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Land"
          :value="stammdaten.land"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Kontoinhaber"
          :value="stammdaten.kontoinhaber"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="IBAN"
          :value="stammdaten.iban"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="BIC"
          :value="stammdaten.bic"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Telefon 1"
          :value="stammdaten.telefon1"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
        <v-text-field
          label="Telefon 2"
          :value="stammdaten.telefon2"
          readonly
          outlined
          disabled
          hide-details
          class="mb-3"
        />
      </v-container>
    </scroller>
    <stammdaten-dialog />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatMixin from '@/formatMixin';
import EventBus from '@/eventBus';
import StammdatenDialog from '@/views/Settings/StammdatenDialog.vue';

export default formatMixin.extend({
  // ggfs andere Lösung falls mehrere mixins verwendet werden sollen
  // https://stackoverflow.com/questions/51873087/unable-to-use-mixins-in-vue-with-typescript
  // https://www.npmjs.com/package/vue-typed-mixins
  components: {
    'stammdaten-dialog': StammdatenDialog,
  },
  mixins: [formatMixin],
  data: () => ({}),
  computed: mapGetters(['stammdaten']),
  methods: {
    refresh(done) {
      this.$store
        .dispatch('syncStammdaten')
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    showStammdatenDialog() {
      EventBus.$emit('showStammdatenDialog');
    },
  },
});
</script>
