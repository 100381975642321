<template>
  <v-container>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Passwort zurücksetzen</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="email"
                prepend-icon="mail"
                name="email"
                label="Email"
                type="email"
                :rules="[rules.required, rules.email]"
                required
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="mb-12">
            <v-spacer />
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="sendMail"
              >
                Passwort zurücksetzen
              </v-btn>
            <v-btn
              class="ml-2"
              color="secondary"
              @click="unshowPasswordReset"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import EventBus from '../eventBus';

export default Vue.extend({
  name: 'PasswordResetComponent',
  components: {
  },
  data: () => ({
    valid: false,
    email: '',
    rules: {
      email: (v: string) => /.+@.{2,}\..{2,}/.test(v) || 'Ungültige Email',
      required: (v: boolean) => !!v || 'Pflichtfeld',
    },
    menu2: false,
    showPasswort: false,
  }),
  methods: {
    async sendMail() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          EventBus.$emit('showSnackbar', 'Passwort Reset Mail wurde versendet!');
          this.unshowPasswordReset();
        })
        .catch((error) => {
          console.log(error);
          EventBus.$emit('showSnackbar', { error: true, text: 'Es ist ein Fehler aufgetreten.' });
        });
    },
    unshowPasswordReset() {
      EventBus.$emit('unshowPasswordReset');
    },
  },
});
</script>
