import router from '.';
import store from '../store';

router.beforeEach(async (to, from, next) => {
  // if (store.getters.isAuthenticated && !store.getters.authorization) {
  //   await store.dispatch('syncAuthorization');
  // }

  if (!store.getters.isAuthenticated && to.name !== 'home') {
    return next({ name: 'home' });
  } if (store.getters.isAuthenticated && to.name !== 'home') {
    // noch irgendwo ungültige routes abfangen du musst
    if (from.path) {
      if (from.path.startsWith('/mitteilungen')) {
        store.dispatch('setLastMitteilungenRoute', { path: from.path });
      } else if (from.path.startsWith('/meine_jobs')) {
        store.dispatch('setLastMeineJobsRoute', { path: from.path });
      } else if (from.path.startsWith('/jobangebote')) {
        store.dispatch('setLastJobangeboteRoute', { path: from.path });
      } else if (from.path.startsWith('/settings')) {
        store.dispatch('setLastSettingsRoute', { path: from.path });
      }
    }
    return next();
  } if (store.getters.isAuthenticated && to.name === 'home') {
    return next({ name: 'mitteilungen' });
  }
  return next();
});
