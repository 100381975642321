import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { isAuthReady } from './firebase';
import EventBus from './eventBus';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.NODE_ENV === 'production' ? 'https://app.parkour.org/api' : 'http://localhost:3001/api',
  timeout: 10000,
  params: {}, // do not remove this, its added to add params later in the config
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      EventBus.$emit('logout');
      EventBus.$emit('showSnackbar', 'Bitte anmelden');
    } else if (error.response && error.response.status === 500) {
      EventBus.$emit('showSnackbar', { error: true, text: error.response.data });
    } else if (!error.status && !error.response) {
      EventBus.$emit('showSnackbar', { error: true, text: 'Keine Verbindung zum Server. Versuche es später.' });
    } else if (error.code === 'ERR_NETWORK') EventBus.$emit('showSnackbar', { error: true, text: 'Keine Netzwerkverbindung. Versuche es später.' });
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  async (request) => {
    await isAuthReady;
    const { currentUser } = getAuth();
    if (!currentUser) return request;

    const authToken = await currentUser.getIdToken();
    // eslint-disable-next-line no-param-reassign
    if (!request.headers) request.headers = {};
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = authToken;
    return request;
  },
);

export default instance;
