<template>
  <v-dialog
    v-if="showArbeitszeitenDialog"
    v-model="showArbeitszeitenDialog"
    width="500"
    fullscreen
    scrollable
  >
    <v-card>
      <v-card-title>Arbeitszeit</v-card-title>
      <v-divider />
      <v-container style="overflow-y: scroll">
        <v-select
          v-model="arbeitszeit.tenantId"
          :items="organisationen"
          item-value="tenantId"
          item-text="data.name"
          label="Organisation"
          required
          outlined
        />
        <v-select
          v-model="arbeitszeit.anstellungId"
          :items="orgAnstellungen"
          item-value="id"
          item-text="typ"
          label="Anstellung"
          required
          outlined
        />
        <v-select
          v-model="arbeitszeit.jobTyp"
          :items="jobTypen"
          label="Job Typ"
          required
          outlined
        />
        <v-text-field
          v-model="arbeitszeit.beschreibung"
          label="Beschreibung"
          outlined
        />
        <div>
          <v-text-field
            :value="arbeitszeitDatumLeserlich"
            label="Datum"
            append-icon="event"
            readonly
            outlined
            @click="showDatePicker"
          />

          <v-dialog
            v-model="datePicker"
            width="290px"
            persistent
          >
            <v-date-picker
              v-model="datePickerValue"
              scrollable
              locale="de-DE"
            >
              <v-spacer />
              <v-btn
                color="primary"
                @click="datePicker = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                :disabled="!datePickerValue"
                @click="datePickerSuccess()"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <v-select
          v-model="arbeitszeit.abrechnungNotiz"
          :items="abrechnungNotizen"
          label="Urlaub / Feiertag / Krank"
          outlined
          clearable
        />
        <div
          v-if="!arbeitszeit.abrechnungNotiz"
          class="d-flex justify-startZeit"
        >
          <v-text-field
            v-model="arbeitszeit.startZeit"
            label="Start"
            readonly
            style="max-width: 80px"
            outlined
            @click="showTimePicker('startZeit')"
          />
          <v-text-field
            v-model="arbeitszeit.endeZeit"
            label="Ende"
            readonly
            style="max-width: 80px"
            class="ml-2"
            outlined
            @click="showTimePicker('endeZeit')"
          />
          <v-text-field
            v-model="arbeitszeit.pause"
            label="Pause"
            readonly
            style="max-width: 80px"
            class="ml-2"
            outlined
            @click="showTimePicker('pause')"
          />
        </div>
        <v-dialog
          ref="dialog"
          v-model="timePicker"
          width="290px"
        >
          <v-time-picker
            v-if="timePicker"
            v-model="timePickerValue"
            format="24hr"
            :allowed-minutes="allowedMinutes"
          >
            <v-spacer />
            <v-btn
              color="primary"
              @click="timePicker = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="primary"
              @click="timePickerSuccess()"
            >
              Ok
            </v-btn>
          </v-time-picker>
        </v-dialog>
        <v-textarea
          v-model="arbeitszeit.mitarbeiterNotiz"
          outlined
        >
          <template #label>
            <div>Notizen</div>
          </template>
        </v-textarea>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          :disabled="!isFormValid"
          text
          @click="saveArbeitszeit()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="showArbeitszeitenDialog = false"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import EventBus from '../../../eventBus';
import formatMixin from '../../../formatMixin';
import api from '../../../api';

const initData = {
  showArbeitszeitenDialog: false,
  timePicker: false,
  datePicker: false,
  datePickerValue: null,
  arbeitszeit: {
    tenantId: null,
    anstellungId: null,
    startZeit: '00:00',
    endeZeit: '00:00',
    pause: '00:00',
    datum: null,
    beschreibung: null,
    abrechnungNotiz: null,
    mitarbeiterNotiz: null,
    jobTyp: null,
  },
  timePickerValue: '00:00',
  timePickerJobTagZeitSpalte: '',
  abrechnungNotizen: ['Urlaub', 'Feiertag', 'Krank'],
};

interface StartStopPause {
  startZeit: string | null;
  endeZeit: string | null;
  pause: string | null;
}
export default formatMixin.extend({
  mixins: [formatMixin],
  data: () => ({
    ...initData,
  }),
  computed: {
    filterbareOrganisationen(): any[] {
      if (this.$store.getters.settings && this.$store.getters.settings.filter) {
        return this.$store.getters.settings.filter.organisationen.slice();
      }
      return [];
    },
    filterbareJobTypen(): any[] {
      if (this.$store.getters.settings && this.$store.getters.settings.filter) {
        return this.$store.getters.settings.filter.jobTypen.slice();
      }
      return [];
    },
    organisationen(): any[] {
      return this.$store.getters.organisationen;
    },
    arbeitszeitDatumLeserlich(): any {
      return this.arbeitszeit.datum
        ? this.datumLeserlichKurz(this.arbeitszeit.datum) : '';
    },
    anstellungen(): any[] {
      return this.$store.getters.anstellungen;
    },
    jobTypen(): any[] {
      return this.$store.getters.jobTypen;
    },
    orgAnstellungen(): any[] {
      const result = this.anstellungen.find(
        (el: any) => String(el.tenantId) === this.arbeitszeit.tenantId,
      );
      if (!result) return [];
      return result.anstellungen.slice().filter((el: any) => el.darfArbeitszeitenEintragen);
    },
    isFormValid(): boolean {
      if (!this.arbeitszeit?.datum) return false;
      if (!this.arbeitszeit?.jobTyp) return false;
      // if(!this.arbeitszeit?.abrechnungNotiz) return false;
      if (this.arbeitszeit?.startZeit === this.arbeitszeit?.endeZeit) return false;
      return true;
    },
  },
  mounted() {
    EventBus.$on('showArbeitszeitenDialog', () => {
      // this.filterbareOrganisationenLocal = this.filterbareOrganisationen.slice(); ?? why
      this.resetComponentData();
      if (this.organisationen && this.organisationen.length > 0) {
        this.arbeitszeit.tenantId = this.organisationen[0].tenantId;
      }
      if (this.orgAnstellungen && this.orgAnstellungen.length > 0) {
        this.arbeitszeit.anstellungId = this.orgAnstellungen[0].id;
      }
      this.showArbeitszeitenDialog = true;
    });
  },
  methods: {
    resetComponentData(): void {
      // https://www.carlcassar.com/articles/reset-data-in-a-vue-component falls es nicht funzt
      Object.assign(this.$data, { ...initData });
    },
    showDatePicker(): void {
      this.datePickerValue = this.arbeitszeit.datum;
      this.datePicker = true;
    },
    datePickerSuccess(): void {
      this.arbeitszeit.datum = this.datePickerValue;
      this.datePicker = false;
    },
    showTimePicker(spalte: string): void {
      this.timePickerJobTagZeitSpalte = spalte;
      // eslint-disable-next-line max-len
      this.timePickerValue = this.arbeitszeit[spalte as keyof StartStopPause];
      this.timePicker = true;
    },
    timePickerSuccess(): void {
      const key = this.timePickerJobTagZeitSpalte as keyof StartStopPause;
      this.arbeitszeit[key] = this.timePickerValue;
      this.timePicker = false;
    },
    saveArbeitszeit():void {
      this.$store.dispatch('setLoading', { state: true, message: 'Sende Arbeitszeit' });
      api
        .postArbeitszeit(this.arbeitszeit)
        .then(() => {
          this.$store.dispatch('syncArbeitszeiten');
          this.showArbeitszeitenDialog = false;
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    allowedMinutes: (m: any): boolean => m % 5 === 0,
  },
});
</script>

<style></style>
