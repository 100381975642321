<template>
  <div>
    <scroller
      :on-refresh="refreshJobData"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <jobansicht-job-info :job="job" />
    </scroller>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import JobansichtJobInfo from '@/components/JobansichtJobInfo.vue';
import EventBus from '@/eventBus';

export default Vue.extend({
  components: {
    'jobansicht-job-info': JobansichtJobInfo,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['zuweisungen']),
    job(): any {
      return this.$store.getters.meineJobsJobansichtJob;
    },
    jobMeta(): any {
      const { uuid } = this.job;
      const { zuweisungen } = this.$store.getters;
      const bewerbungMeta = zuweisungen.find((el: any) => el.uuid === uuid);
      return bewerbungMeta;
    },
  },
  methods: {
    refreshJobData(done: ()=> void) {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    isJobAbgeschlossen() {
      return this.job.status === 'Erledigt';
    },
  },
});
</script>

<style></style>
