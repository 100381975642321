<template>
  <div>
    <scroller
      :on-refresh="refreshJobData"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <jobansicht-job-info :job="job" />
      <div class="mt-2">
        <!-- <v-btn
          v-if="isErsatzsucheMoeglich(job)"
          :disabled="isJobAbgeschlossen()"
          color="primary"
          class="mr-1"
          @click="dialogErsatzsucheStarten = true"
        >
          Ersatzsuche
        </v-btn>
        <v-btn
          v-if="!isErsatzsucheMoeglich(job) && !isJobAbgeschlossen()"
          :disabled="isJobAbgeschlossen()"
          color="primary"
          class="mr-1"
          @click="dialogErsatzsucheBeenden = true"
        >
          Ersatzsuche beenden
        </v-btn>
        <v-btn v-if="!isJobAbgeschlossen()" color="primary" @click="dialogAbsagen = true">
          Absagen
        </v-btn> -->
      </div>
    </scroller>

    <v-dialog
      v-model="dialogErsatzsucheStarten"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Ersatzsuche Starten </v-card-title>
        <v-card-text>
          <p>Während der Ersatzsuche sind keine Eingabgen unter "Teilnehmer" und "Orga" möglich.</p>
          <p>Falls du die Ersatzsuche starten möchtest klicke auf "Senden".</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialogErsatzsucheStarten = false"> Abbrechen </v-btn>
          <v-btn color="primary" text @click="versendeErsatzsucheStarten"> Senden </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogErsatzsucheBeenden"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Ersatzsuche Beenden </v-card-title>
        <v-card-text> Falls du die Ersatzsuche beenden möchtest klicke auf "Senden". </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialogErsatzsucheBeenden = false"> Abbrechen </v-btn>
          <v-btn color="primary" text @click="versendeErsatzsucheBeenden"> Senden </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAbsagen"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Absagen </v-card-title>
        <v-card-text>
          Falls du diesen Job nicht machen kannst klicke auf "Senden" um ihn wieder frei zu geben.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialogAbsagen = false"> Abbrechen </v-btn>
          <v-btn color="primary" text @click="versendeAbsage"> Senden </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import JobansichtJobInfo from '@/components/JobansichtJobInfo.vue';
import EventBus from '@/eventBus';
import api from '../../../api';

export default Vue.extend({
  components: {
    'jobansicht-job-info': JobansichtJobInfo,
  },
  data: () => ({
    dialogErsatzsucheStarten: false,
    dialogErsatzsucheBeenden: false,
    dialogAbsagen: false,
  }),
  computed: {
    ...mapGetters(['zuweisungen']),
    job(): any {
      return this.$store.getters.meineJobsJobansichtJob;
    },
    jobMeta(): any {
      const { uuid } = this.job;
      const { zuweisungen } = this.$store.getters;
      const bewerbungMeta = zuweisungen.find((el: any) => el.uuid === uuid);
      return bewerbungMeta;
    },
  },
  methods: {
    refreshJobData(done: ()=> void) {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    isErsatzsucheMoeglich(job: any) {
      const validStati = ['Vergeben'];
      return this.job.status && validStati.indexOf(this.job.status) !== -1;
    },
    versendeErsatzsucheStarten() {
      const { uuid } = this.job;
      const anstellungId = this.jobMeta.mitarbeiterAnstellungId;
      const data = {
        uuid,
        anstellungId,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Starte Ersatzsuche' });
      api
        .postErsatzsuche(data)
        .then(() => {
          this.$store.dispatch('updateJobStatus', { uuid, status: 'Ersatzsuche' });
          this.dialogErsatzsucheStarten = false;
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    versendeErsatzsucheBeenden() {
      const { uuid } = this.job;
      const anstellungId = this.jobMeta.mitarbeiterAnstellungId;
      const data = {
        uuid,
        anstellungId,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Beende Ersatzsuche' });
      api
        .postErsatzsucheBeenden(data)
        .then(() => {
          this.$store.dispatch('updateJobStatus', { uuid, status: 'Vergeben' });
          this.dialogErsatzsucheBeenden = false;
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    versendeAbsage() {
      const { uuid } = this.job;
      const anstellungId = this.jobMeta.mitarbeiterAnstellungId;

      const data = {
        uuid,
        anstellungId,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Job wird abgesagt' });
      api
        .postJobAbsage(data)
        .then(() => {
          this.dialogAbsagen = false;
          EventBus.$emit('closeCurrentView');
          EventBus.$emit('showSnackbar', 'Job wurde abgesagt');
          this.$store
            .dispatch('syncZuweisungen')
            .catch((error) => {
              if (error && error.response) {
                console.log(error.response);
                EventBus.$emit('showSnackbar', error.response.data.message);
              }
            });
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    isJobAbgeschlossen() {
      return this.job.status === 'Erledigt';
    },
  },
});
</script>

<style></style>
