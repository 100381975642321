/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import PouchDB from 'pouchdb-browser';
import PouchDBAuthentication from 'pouchdb-authentication';
// import * as PouchDBLocalStorage from 'pouchdb-adapter-localstorage';
import PouchDBIndexedDB from 'pouchdb-adapter-indexeddb';
import EventBus from '../eventBus';
import api from '../api';
import Job from '../classes/job';

PouchDB.plugin(PouchDBAuthentication);
PouchDB.plugin(PouchDBIndexedDB);
// PouchDB.plugin(PouchDBLocalStorage);
// type CommitType = { commit: (action: string, payload?: any) => void };
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

let localDB: PouchDB.Database;
let remoteDB: PouchDB.Database;
let syncHandler: any;

const initialSyncState = () => ({
  data: [],
  conflicts: [],
});

interface SyncState {
  data: any[];
  conflicts: any[];
}

export interface State {
  user: any,
  isAuthenticated: boolean,
  authorization: any,
  // authToken: null,
  stammdaten: any,
  settings: { filter: { organisationen: any[], jobTypen: any[] } },
  ausschreibungen: any[],
  organisationen: any[],
  organisationenBewerbbar: any[],
  organisationenBewerbungen: any[],
  anstellungen: Job[],
  bewerbungen: Job[],
  zuweisungen: Job[],
  erledigte: Job[],
  arbeitszeiten: any[],
  jobTypen: any[],
  mitgliedQualifikationen: any[],
  jobs: Record<string, Job>,
  jobangeboteJobUID: string | null,
  meineJobsJobansichtJobUID: string | null,
  isLoading: boolean,
  loadingMessage: string,
  lastMitteilungenRoute: string,
  lastMeineJobsRoute: string,
  lastJobangeboteRoute: string,
  lastSettingsRoute: string,
  jobansichtBewerbungenReturnPath: string | null,
  jobansichtBevorstehendeReturnPath: string | null,
  jobansichtDurchgefuehrteReturnPath: string | null,
  testStore: any,
}

const syncStore = {
  namespaced: true,
  state: initialSyncState,
  mutations: {
    SET_INIT_STATE(state: SyncState, payload: any) {
      state.data = payload;
      console.log('syncstore initialState was set to', payload);
    },
    DELETE_DOC(state: SyncState, payload: any) {
      const { docId } = payload;
      const index = state.data.findIndex((d: any) => d._id === docId);
      if (index !== -1) {
        state.data.splice(index, 1);
      }
    },
    UPDATE_DOC(state: SyncState, payload: any) {
      const { doc } = payload; //
      const docId = doc._id;
      const index = state.data.findIndex((d: any) => d._id === docId);
      if (index !== -1) {
        if (state.data[index]._rev !== doc._rev) {
          Vue.set(state.data, index, doc);
          // state.data[index] = doc
        } else {
          console.log('cancel syncStore update because already up to date');
        }
      } else {
        state.data.push(doc);
      }
    },
    RESET_STATE(state: SyncState) {
      Object.assign(state, initialSyncState());
    },
    SET_SYNC_HANDLER(state: SyncState, payload: any) {
      syncHandler = payload;
    },
    ADD_CONFLICT(state: SyncState, payload: any) {
      state.conflicts.push(payload);
    },
    DELETE_CURRENT_CONFLICT(state: SyncState) {
      state.conflicts.shift();
    },
  },
  actions: {
    async setupStore({ commit, state }: { commit: (action: string, payload?: any) => void,
      state: SyncState}) {
      // const ua = navigator;
      // console.log(ua);
      localDB = new PouchDB('mylocaldb', { adapter: 'idb' });

      // all changes to localDB are handled here
      localDB
        .changes({
          since: 'now',
          live: true,
          include_docs: true,
          conflicts: true,
        })
        .on('change', async (info: any) => {
          console.log('localDB change');
          console.log(info);
          const { doc } = info;

          if (doc._conflicts) {
            const conflictingRev = doc._conflicts[0];
            const docId = doc._id;
            const syncStoreRev = state.data[0]._rev;
            console.log('syncStoreRev', syncStoreRev);
            const isConflictWinnerLocal = doc._rev === syncStoreRev;
            const conflictingDoc = await localDB.get(docId, { rev: conflictingRev });
            console.log('conflict:');
            console.log('doc taken:');
            console.log(doc);
            console.log('conflictingDoc:');
            console.log(conflictingDoc);
            commit('ADD_CONFLICT', { doc, conflictingDoc, isConflictWinnerLocal });
            // muss gelöscht werden weil sonst weiter conflict
            await localDB.remove(docId, conflictingRev);
            commit('UPDATE_DOC', { doc });
            EventBus.$emit('showSyncConflictDialog');
          } else if (doc._deleted) {
            commit('DELETE_DOC', { docId: doc._id });
            console.log('deleted doc from syncStore', doc);
          } else {
            commit('UPDATE_DOC', { doc });
            console.log('updated doc from syncStore', doc);
          }
        })
        .on('error', (error: any) => {
          console.log('change error');
          console.log(error);
        });

      localDB.on('destroyed', () => {
        console.log('done destroying');
      });

      // const baseURI = SERVER_URL + '/couchdb-access-token'
      // var config = {
      //     withCredentials: true
      // }

      // let couchDBAuth = null;
      // let dbName = null;
      // let userName = null;

      // try {
      //   const response = await api.getCouchDBAccessToken();
      //   couchDBAuth = response.data.token;
      //   dbName = response.data.db;
      //   userName = response.data.user;
      // } catch (error) {
      //   console.log(error);
      // }

      // überträgt alle in localDB vorgehaltenen Daten in den syncStore
      // const docs = await localDB.allDocs({ include_docs: true });
      // const initialState = docs.rows.map((row: any) => row.doc);
      // console.log('initialState', initialState);
      // commit('SET_INIT_STATE', initialState);

      // if (couchDBAuth) {
      //   console.log('entered couchdbblock');
      //   const remoteDBURL = `https://dev.parkour.org:6984/${dbName}`;
      //   console.log('remoteDBURL: ', remoteDBURL);
      //   remoteDB = new PouchDB(remoteDBURL, {
      //     skip_setup: true,
      //     fetch(url, opts) {
      //       const newOpts: RequestInit = { ...opts, credentials: 'include' };
      //       return PouchDB.fetch(url, newOpts);
      //     },
      //   });

      //   await remoteDB.logIn(userName, couchDBAuth);

      //   // hier war der syncstore initialState setup

      //   syncHandler = PouchDB.sync(localDB, remoteDB, {
      //     live: true,
      //     retry: true,
      //     // conflicts: true, deprecated???
      //   })
      //     .on('error', (error) => {
      //       console.log('sync error');
      //       console.log(error);
      //     });
      // } else {
      //   // Falls in der Login Phase kein remote aufgebaut werden kann sollte
      //   // hier abgebrochen werden ...
      //   // howto check if login phase? shit... muss dann local mit store replicaten
      //   console.log('war nicht in couchDBAuth block');
      // }
    },
    // async deleteFirstDoc ({ commit, state }) {
    //     const docs = state.data
    //     if (!docs || docs.length === 0) {
    //         console.log('no doc in syncStore')
    //         return
    //     }
    //     const doc = docs[0]
    //     const docId = doc._id
    //     const docRev = doc._rev
    //     try {
    // löscht nur die revisiion ?, macht also zB rückgängig?
    //         await localDB.remove(docId, docRev)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // },
    // async increaseFirstDoc ({ commit, state }) {
    //     const docs = state.data
    //     if (!docs || docs.length === 0) {
    //         console.log('no doc in syncStore')
    //         return
    //     }
    //     const doc = { ...docs[0] }
    //     doc.data.count++
    //     try {
    //         const response = await localDB.put(doc)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // },
    async reset({ commit, state }: { commit: (action: string, payload?: any) => void,
      state: SyncState}) {
      if (syncHandler) {
        syncHandler.cancel();
        syncHandler = null;
      }
      try {
        await localDB.destroy();
        await remoteDB.close();
      } catch (err) {
        console.log(err);
      }
      commit('RESET_STATE');
    },
    async isDocInSync({ commit }: { commit: any}, { docId }: {docId: string}) {
      let localDoc;
      try {
        // prüfen ob es ein localDoc gibt
        localDoc = await localDB.get(docId);
      } catch (error: any) {
        if (error.status === 404) {
          // es gibt kein localDoc und somit in sync
          return true;
        }
        // sonstige fehler
        throw new Error('Problem mit lokaler Sync Datenbank');
      }

      // prüfen ob Verbindung zu remoteDB existiert
      if (!remoteDB) {
        throw new Error('Keine Verbindung zum Sync Server. Versuche es später.');
      }

      let remoteDoc;
      try {
        // prüfen ob es ein remoteDoc gibt
        remoteDoc = await remoteDB.get(docId);
      } catch (error) {
        throw new Error('Problem mit Sync Server');
      }

      // prüfen ob localDoc und remoteDoc übereinstimmen
      return localDoc._rev === remoteDoc._rev;
    },
    deleteCurrentConflict({ commit }: { commit: any}) {
      commit('DELETE_CURRENT_CONFLICT');
    },
    async setTeilnehmerAnwesenheit({ commit }: { commit: (action: string, payload?: any) => void
    }, { docId, anmeldungId, anwesend } : {docId: string, anmeldungId: string, anwesend: boolean}) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          if (newDoc.data.teilnehmer) {
            if (newDoc.data.teilnehmer[anmeldungId]) {
              newDoc.data.teilnehmer[anmeldungId].anwesend = anwesend;
            } else {
              newDoc.data.teilnehmer[anmeldungId] = { anwesend };
            }
          } else {
            newDoc.data.teilnehmer = {};
            newDoc.data.teilnehmer[anmeldungId] = { anwesend };
          }
        } else {
          newDoc = { _id: docId, data: { teilnehmer: { [anmeldungId]: { anwesend } } } };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async createJobDoc({ commit }: { commit: (action: string, payload?: any) => void
    }, { docId, data = { teilnehmer: {} } }: {docId: string, data: any}) {
      try {
        const doc = { _id: docId, data };
        await localDB.put(doc);
      } catch (error) {
        console.log(error);
      }
    },
    async setTeilnehmerZahlungseingang({ commit }: { commit: (action: string, payload?: any) => void
    }, { docId, anmeldungId, betrag }: {docId: string, anmeldungId: string, betrag: any}) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          if (newDoc.data.teilnehmer) {
            if (newDoc.data.teilnehmer[anmeldungId]) {
              newDoc.data.teilnehmer[anmeldungId].betrag = betrag;
            } else {
              newDoc.data.teilnehmer[anmeldungId] = { betrag };
            }
          } else {
            newDoc.data.teilnehmer = {};
            newDoc.data.teilnehmer[anmeldungId] = { betrag };
          }
        } else {
          newDoc = { _id: docId, data: { teilnehmer: { [anmeldungId]: { betrag } } } };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async addTeilnehmerQualifikation({ commit }: { commit: (action: string, payload?: any) => void
    }, { docId, anmeldungId, qualifikationId }: {docId: string, anmeldungId: string,
      qualifikationId: string}) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          if (newDoc.data.teilnehmer) {
            if (newDoc.data.teilnehmer[anmeldungId]) {
              if (newDoc.data.teilnehmer[anmeldungId].qualifikationen) {
                newDoc.data.teilnehmer[anmeldungId].qualifikationen.push(qualifikationId);
              } else {
                newDoc.data.teilnehmer[anmeldungId].qualifikationen = [qualifikationId];
              }
            } else {
              newDoc.data.teilnehmer[anmeldungId] = { qualifikationen: [qualifikationId] };
            }
          } else {
            newDoc.data.teilnehmer = {};
            newDoc.data.teilnehmer[anmeldungId] = { qualifikationen: [qualifikationId] };
          }
        } else {
          newDoc = {
            _id: docId,
            data:
            { teilnehmer: { [anmeldungId]: { qualifikationen: [qualifikationId] } } },
          };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteTeilnehmerQualifikation(
      { commit }: { commit: any },
      { docId, anmeldungId, qualifikationId } : {docId: string, anmeldungId: string,
      qualifikationId: string},
    ) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc: any;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          if (newDoc.data.teilnehmer) {
            if (newDoc.data.teilnehmer[anmeldungId]) {
              if (newDoc.data.teilnehmer[anmeldungId].qualifikationen) {
                const index = newDoc.data.teilnehmer[anmeldungId].qualifikationen
                  .findIndex((el: string) => el === qualifikationId);
                newDoc.data.teilnehmer[anmeldungId].qualifikationen.splice(index, 1);
              }
            }
          }
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async setJobTagZeiten(
      { commit }: { commit: any },
      { docId, jobTagId, zeiten }: { docId: string, jobTagId: string, zeiten: any},
    ) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          if (!newDoc.data.jobTage) {
            newDoc.data.jobTage = {};
          }
          newDoc.data.jobTage[jobTagId] = zeiten;
        } else {
          newDoc = { _id: docId, data: { jobTage: { [jobTagId]: zeiten } } };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async setJobNotizen(
      { commit }: {commit: any},
      { docId, notizen }: { docId: string, notizen: string},
    ) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          newDoc.data.notizen = notizen;
        } else {
          newDoc = { _id: docId, data: { notizen } };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async setJobZahlungseingang(
      { commit }: {commit: any},
      { docId, zahlungseingang }: { docId: string, zahlungseingang: any},
    ) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          newDoc.data.zahlungseingang = zahlungseingang;
        } else {
          newDoc = { _id: docId, data: { zahlungseingang } };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
    async setJobTeilnehmeranzahl(
      { commit }: {commit: any},
      { docId, teilnehmerAnzahl }: {docId: string, teilnehmerAnzahl: number},
    ) {
      let oldDoc: any;
      let docExists = false;
      try {
        oldDoc = await localDB.get(docId);
        docExists = true;
      } catch (error: any) {
        if (error.reason && error.reason === 'missing') {
          console.log('doc does not exist yet');
        } else {
          console.log(error);
        }
      }

      try {
        let newDoc;
        if (docExists) {
          newDoc = { _id: oldDoc._id, _rev: oldDoc._rev, data: oldDoc.data };
          newDoc.data.teilnehmerAnzahl = teilnehmerAnzahl;
        } else {
          newDoc = { _id: docId, data: { teilnehmerAnzahl } };
        }
        await localDB.put(newDoc);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getStateData(state: SyncState) {
      return state.data;
    },
    getCurrentConflict(state: SyncState) {
      if (!state.conflicts || state.conflicts.length === 0) {
        return null;
      }
      return state.conflicts[0];
    },
    getJobDoc(state: SyncState, getters: any, rootState: State) {
      const docId = rootState.meineJobsJobansichtJobUID;
      const result = state.data.find((el) => el._id === docId);
      if (result) {
        return result.data;
      }
      return null;
    },
  },
};

const initialState = (): State => ({
  user: null,
  isAuthenticated: false,
  authorization: null,
  // authToken: null,
  stammdaten: null,
  settings: { filter: { organisationen: [], jobTypen: [] } },
  ausschreibungen: [],
  organisationen: [],
  organisationenBewerbbar: [],
  organisationenBewerbungen: [],
  anstellungen: [],
  bewerbungen: [],
  zuweisungen: [],
  erledigte: [],
  arbeitszeiten: [],
  jobTypen: [],
  mitgliedQualifikationen: [],
  jobs: {},
  jobangeboteJobUID: null,
  meineJobsJobansichtJobUID: null,
  isLoading: false,
  loadingMessage: '',
  lastMitteilungenRoute: '/mitteilungen',
  lastMeineJobsRoute: '/meine_jobs',
  lastJobangeboteRoute: '/jobangebote',
  lastSettingsRoute: '/settings',
  jobansichtBewerbungenReturnPath: null,
  jobansichtBevorstehendeReturnPath: null,
  jobansichtDurchgefuehrteReturnPath: null,
  testStore: null,
});

export default new Vuex.Store<State>({
  state: initialState,
  getters: {
    //  um auf state zuzugreifen, vorallem wenn man noch logik wie zB filterung anwenden will
    //  man kann auch ohne getters direkt auf state zugreifen wenn man keine extra logik braucht
    isAuthenticated(state) {
      return state.user !== null && state.user !== undefined && state.isAuthenticated;
    },
    authorization(state) {
      return state.authorization;
    },
    // authToken (state) {
    //     return state.authToken
    // },
    stammdaten(state) {
      return state.stammdaten;
    },
    testStore(state) {
      return state.testStore;
    },
    testStore2(state) {
      return state.testStore.getTime();
    },
    ausschreibungen(state): Job[] {
      return state.ausschreibungen;
    },
    ausschreibungenGefiltert(state): Job[] {
      let jobs: Job[] = [];
      const validOrganisationsIds: string[] = [];
      const validJobTypen: any[] = [];
      if (state.settings) {
        state.settings.filter.organisationen.forEach((el) => {
          if (el.anzeigen) {
            validOrganisationsIds.push(el.tenantId);
          }
        });

        state.settings.filter.jobTypen.forEach((el) => {
          if (el.anzeigen) {
            validJobTypen.push(el.jobTyp);
          }
        });

        if (state.ausschreibungen) {
          state.ausschreibungen.forEach((job) => {
            if (validOrganisationsIds.indexOf(job.tenantId) !== -1) {
              if (validJobTypen.indexOf(job.typ) !== -1) {
                jobs.push(job);
              }
            }
          });
        }
      } else {
        jobs = state.ausschreibungen;
      }

      return jobs;
    },
    organisationen(state) {
      return state.organisationen;
    },
    organisationenBewerbbar(state) {
      return state.organisationenBewerbbar;
    },
    organisationenBewerbungen(state) {
      return state.organisationenBewerbungen;
    },
    anstellungen(state) {
      return state.anstellungen;
    },
    bewerbungen(state) {
      return state.bewerbungen;
    },
    bewerbungenGefiltert(state): Job[] {
      let jobs: Job[] = [];
      const validOrganisationsIds: string[] = [];
      if (state.settings) {
        state.settings.filter.organisationen.forEach((el) => {
          if (el.anzeigen) {
            validOrganisationsIds.push(el.tenantId);
          }
        });
        const validJobTypen: any[] = [];
        state.settings.filter.jobTypen.forEach((el) => {
          if (el.anzeigen) {
            validJobTypen.push(el.jobTyp);
          }
        });
        if (state.bewerbungen) {
          state.bewerbungen.forEach((job) => {
            if (validOrganisationsIds.indexOf(job.tenantId) !== -1) {
              if (validJobTypen.indexOf(job.type) !== -1) {
                jobs.push(job);
              }
            }
          });
        }
      } else {
        jobs = state.bewerbungen;
      }
      return jobs;
    },
    zuweisungen(state): Job[] {
      return state.zuweisungen;
    },
    erledigte(state): Job[] {
      return state.erledigte;
    },
    arbeitszeiten(state) {
      return state.arbeitszeiten;
    },
    bevorstehendeGefiltert(state): Job[] {
      let jobs: Job[] = [];
      const validOrganisationsIds: string[] = [];
      if (!state.settings) { jobs = state.zuweisungen; }
      if (state.settings) {
        state.settings.filter.organisationen.forEach((el) => {
          if (el.anzeigen) {
            validOrganisationsIds.push(el.tenantId);
          }
        });
        const validJobTypen: any[] = [];
        state.settings.filter.jobTypen.forEach((el) => {
          if (el.anzeigen) {
            validJobTypen.push(el.jobTyp);
          }
        });
        if (state.zuweisungen) {
          const nowInMillisec = new Date().getTime();
          state.zuweisungen.forEach((job) => {
            if (validOrganisationsIds.includes(job.tenantId)) {
              if (validJobTypen.includes(job.type)) {
                const uhrzeit = job.endTime.split(':');
                const stunden = Number(uhrzeit[0]);
                const minuten = Number(uhrzeit[1]);
                const cmpDate = new Date(job.endDate).setHours(stunden, minuten, 0, 0);
                if (cmpDate >= nowInMillisec) {
                  jobs.push(job);
                }
              }
            }
          });
        }
      }
      return jobs;
    },
    durchgefuehrteGefiltert(state): Job[] {
      let jobs: Job[] = [];
      const validOrganisationsIds: string[] = [];
      if (state.settings) {
        state.settings.filter.organisationen.forEach((el) => {
          if (el.anzeigen) {
            validOrganisationsIds.push(el.tenantId);
          }
        });
        const validJobTypen: any[] = [];
        state.settings.filter.jobTypen.forEach((el) => {
          if (el.anzeigen) {
            validJobTypen.push(el.jobTyp);
          }
        });
        if (state.zuweisungen) {
          const nowInMillisec = new Date().getTime();
          state.zuweisungen.forEach((job) => {
            if (validOrganisationsIds.includes(job.tenantId)) {
              if (validJobTypen.includes(job.type)) {
                const uhrzeit = job.endTime.split(':');
                const stunden = Number(uhrzeit[0]);
                const minuten = Number(uhrzeit[1]);
                const cmpDate = new Date(job.endDate).setHours(stunden, minuten, 0, 0);
                if (cmpDate < nowInMillisec) {
                  jobs.push(job);
                }
              }
            }
          });
        }
      } else {
        jobs = state.zuweisungen;
      }

      return jobs;
    },
    mitgliedQualifikationen(state) {
      return state.mitgliedQualifikationen;
    },
    settings(state) {
      return state.settings;
    },
    jobTypen(state) {
      return state.jobTypen;
    },
    jobs(state) {
      return state.jobs;
    },
    jobangeboteJob(state): Job | null {
      const uuid = state.jobangeboteJobUID;
      if (!uuid) return null;
      return state.jobs[uuid];
    },
    meineJobsJobansichtJob(state): Job | null {
      const uuid = state.meineJobsJobansichtJobUID;
      if (!uuid) return null;
      return state.jobs[uuid];
    },
    jobansichtBewerbungenReturnPath(state) {
      return state.jobansichtBewerbungenReturnPath;
    },
    jobansichtBevorstehendeReturnPath(state) {
      return state.jobansichtBevorstehendeReturnPath;
    },
    jobansichtDurchgefuehrteReturnPath(state) {
      return state.jobansichtDurchgefuehrteReturnPath;
    },
    isLoading(state) {
      return state.isLoading;
    },
    loadingMessage(state) {
      return state.loadingMessage;
    },
    lastMitteilungenRoute(state) {
      return state.lastMitteilungenRoute;
    },
    lastMeineJobsRoute(state) {
      return state.lastMeineJobsRoute;
    },
    lastJobangeboteRoute(state) {
      return state.lastJobangeboteRoute;
    },
    lastSettingsRoute(state) {
      return state.lastSettingsRoute;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_AUTHENTICATED(state, payload) {
      state.isAuthenticated = payload;
    },
    SET_AUTHORIZATION(state, payload) {
      state.authorization = payload;
    },
    // SET_AUTH_TOKEN (state, payload) {
    //     state.authToken = payload
    // },
    SET_STAMMDATEN(state, payload) {
      state.stammdaten = payload;
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    },
    SET_ORGANISATIONEN(state, payload) {
      state.organisationen = payload;
    },
    SET_ORGANISATIONEN_BEWERBBAR(state, payload) {
      state.organisationenBewerbbar = payload;
    },
    SET_ORGANISATIONEN_BEWERBUNGEN(state, payload) {
      state.organisationenBewerbungen = payload;
    },
    SET_ANSTELLUNGEN(state, payload) {
      state.anstellungen = payload;
    },
    SET_BEWERBUNGEN(state, payload) {
      state.bewerbungen = payload;
    },
    SET_ZUWEISUNGEN(state, payload) {
      state.zuweisungen = payload;
    },
    SET_AUSSCHREIBUNGEN(state, payload) {
      state.ausschreibungen = payload;
    },
    SET_ERLEDIGTE(state, payload) {
      state.erledigte = payload;
    },
    SET_ARBEITSZEITEN(state, payload) {
      state.arbeitszeiten = payload;
    },
    SET_JOB_TYPEN(state, payload) {
      state.jobTypen = payload;
    },
    SET_MITGLIED_QUALIFIKATIONEN(state, payload) {
      state.mitgliedQualifikationen = payload;
    },
    SET_SETTINGS_FILTER_ORGANISATIONEN(state, payload) {
      state.settings.filter.organisationen = payload;
    },
    SET_SETTINGS_FILTER_JOB_TYPEN(state, payload) {
      state.settings.filter.jobTypen = payload;
    },
    SET_JOBANGEBOTE_JOB_UID(state, payload) {
      state.jobangeboteJobUID = payload;
    },
    SET_MEINE_JOBS_JOBANSICHT_JOB_UID(state, payload) {
      state.meineJobsJobansichtJobUID = payload;
    },
    SET_JOB(state, payload) {
      Vue.set(state.jobs, payload.uuid, payload.data);
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_LOADING_MESSAGE(state, payload) {
      state.loadingMessage = payload;
    },
    DELETE_AUSSCHREIBUNG(state, payload) {
      const { uuid } = payload;
      const index = state.ausschreibungen.findIndex((ausschreibung) => ausschreibung.uuid === uuid);
      state.ausschreibungen.splice(index, 1);
    },
    DELETE_BEWERBUNG(state, payload) {
      const { uuid } = payload;
      const index = state.bewerbungen.findIndex((bewerbung) => bewerbung.uuid === uuid);
      if (index !== -1) {
        state.bewerbungen.splice(index, 1);
      }
    },
    DELETE_BEVORSTEHENDE(state, payload) {
      const { uuid } = payload;
      const index = state.zuweisungen.findIndex((zuweisung) => zuweisung.uuid === uuid);

      if (index !== -1) {
        state.zuweisungen.splice(index, 1);
      }
    },
    DELETE_ZUWEISUNG(state, payload) {
      const { uuid } = payload;
      const index = state.zuweisungen.findIndex((zuweisung) => zuweisung.uuid === uuid);

      if (index !== -1) {
        state.zuweisungen.splice(index, 1);
      }
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    },
    SET_LAST_MITTEILUNGEN_ROUTE(state, payload) {
      state.lastMitteilungenRoute = payload;
    },
    SET_LAST_MEINE_JOBS_ROUTE(state, payload) {
      state.lastMeineJobsRoute = payload;
    },
    SET_LAST_JOBANGEBOTE_ROUTE(state, payload) {
      state.lastJobangeboteRoute = payload;
    },
    SET_LAST_SETTINGS_ROUTE(state, payload) {
      state.lastSettingsRoute = payload;
    },
    SET_JOBANSICHT_BEWERBUNGEN_RETURN_PATH(state, payload) {
      state.jobansichtBewerbungenReturnPath = payload;
    },
    SET_JOBANSICHT_BEVORSTEHENDE_RETURN_PATH(state, payload) {
      state.jobansichtBevorstehendeReturnPath = payload;
    },
    SET_JOBANSICHT_DURCHGEFUEHRTE_RETURN_PATH(state, payload) {
      state.jobansichtDurchgefuehrteReturnPath = payload;
    },
    SET_testStore(state, payload) {
      state.testStore = payload;
    },
    UPDATE_JOB_STATUS(state, payload) {
      const { uuid, status } = payload;
      state.jobs[uuid] = { ...state.jobs[uuid], status };
    },
  },
  actions: {
    userLogin({ commit, dispatch }, { userId }) {
      console.log('userLogin', userId);
      commit('SET_USER', userId);
      commit('SET_AUTHENTICATED', true);
      //  wo und wann sollen organisationen geladen werden?
      // dispatch('fetchOperatingData');
      dispatch('syncStore/setupStore');
      // EventBus.$emit('navigate', { name: 'meine_jobs' });
      EventBus.$emit('navigate', { path: '/meine_jobs' });
    },
    userLogout({ commit, dispatch }) {
      // new Promise((resolve, reject) => {
      //     router.push({ path: '/' },
      //         (complete) => { resolve(complete) },
      //         (error) => { reject(error) }
      //     )
      // }).catch((error) => {
      //     console.log(error)
      // }).finally(() => {
      commit('RESET_STATE');
      dispatch('syncStore/reset');
      // })
      EventBus.$emit('navigate', { path: '/' });
    },
    setStammdaten({ commit }, stammdaten) {
      commit('SET_STAMMDATEN', stammdaten);
    },
    testStore({ commit }, data) {
      commit('SET_testStore', data);
    },
    setSettings({ commit }, settings) {
      commit('SET_SETTINGS', settings);
    },
    setOrganisationen({ commit }, organisationen) {
      commit('SET_ORGANISATIONEN', organisationen);
    },
    setAnstellungen({ commit }, anstellungen) {
      commit('SET_ANSTELLUNGEN', anstellungen);
    },
    setJobTypen({ commit }, jobTypen) {
      commit('SET_JOB_TYPEN', jobTypen);
    },
    setMitgliedQualifikationen({ commit }, mitgliedQualifikationen) {
      commit('SET_MITGLIED_QUALIFIKATIONEN', mitgliedQualifikationen);
    },
    setAusschreibungen({ commit }, ausschreibungen) {
      commit('SET_AUSSCHREIBUNGEN', ausschreibungen);
    },
    setBewerbungen({ commit }, bewerbungen) {
      commit('SET_BEWERBUNGEN', bewerbungen);
    },
    setZuweisungen({ commit }, zuweisungen) {
      commit('SET_ZUWEISUNGEN', zuweisungen);
    },
    fetchOperatingData({ dispatch, state }) {
      const opDataToSync = [
        // 'syncStammdaten'
        // 'syncOrganisationen',
        // 'syncOrganisationenBewerbungen',
        // 'syncAnstellungen',
        // 'syncJobtypen',
        // 'syncAusschreibungen',
        // 'syncBewerbungen',
        'syncZuweisungen',
        'syncErledigte',
        // 'syncArbeitszeiten',
        // 'syncMitgliedQualifikationen'
      ];
      dispatch('setLoading', { state: true, message: 'Betriebsdaten werden geladen...' });
      const opDataPromises: any[] = [];
      opDataToSync.forEach((opData) => opDataPromises.push(dispatch(opData)));
      Promise.all(opDataPromises)
        .then((repsonses) => { console.log(repsonses); })
        .catch((error) => {
          // hier vermtl noch abfrage wie bei anderen errors damit
          // "offline"-Meldung nicht überschrieben wird <---------
          EventBus.$emit(
            'showSnackbar',
            `Betriebsdaten konnten nicht vollständig geladen werden: ${error}`,
          );
        }).finally(() => {
          dispatch('setLoading', { state: false, message: '' });
        });
    },
    setSettingsFilter({ commit }, { organisationen, jobTypen }) {
      commit('SET_SETTINGS_FILTER_ORGANISATIONEN', organisationen);
      commit('SET_SETTINGS_FILTER_JOB_TYPEN', jobTypen);
      const settings = {
        organisationen,
        jobTypen,
      };
      return new Promise((resolve, reject) => {
        // api
        //   .postSettings(settings)
        //   .then((response) => {
        //     resolve(response);
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
        resolve(1);
      });
    },
    setJobangeboteJobUID({ commit }, { uuid }) {
      commit('SET_JOBANGEBOTE_JOB_UID', uuid);
    },
    setMeineJobsJobansichtJobUID({ commit }, { uuid }) {
      commit('SET_MEINE_JOBS_JOBANSICHT_JOB_UID', uuid);
    },
    setJob({ commit }, { uuid, data }) {
      commit('SET_JOB', { uuid, data });
    },
    setLoading({ commit }, { state, message }) {
      commit('SET_LOADING', state);
      commit('SET_LOADING_MESSAGE', message);
    },
    deleteJobFromAusschreibungen({ commit, state }) {
      if (!state.jobangeboteJobUID) {
        return;
      }
      commit('DELETE_AUSSCHREIBUNG', { uuid: state.jobangeboteJobUID });
    },
    deleteJobFromBewerbungen({ commit, state }) {
      if (!state.meineJobsJobansichtJobUID) {
        return;
      }
      commit('DELETE_BEWERBUNG', { uuid: state.meineJobsJobansichtJobUID });
    },
    deleteZuweisung({ commit, state }) {
      if (!state.meineJobsJobansichtJobUID) {
        return;
      }
      commit('DELETE_ZUWEISUNG', { uuid: state.meineJobsJobansichtJobUID });
    },
    setLastMitteilungenRoute({ commit }, { path }) {
      commit('SET_LAST_MITTEILUNGEN_ROUTE', path);
    },
    setLastMeineJobsRoute({ commit }, { path }) {
      commit('SET_LAST_MEINE_JOBS_ROUTE', path);
    },
    setLastJobangeboteRoute({ commit }, { path }) {
      commit('SET_LAST_JOBANGEBOTE_ROUTE', path);
    },
    setLastSettingsRoute({ commit }, { path }) {
      commit('SET_LAST_SETTINGS_ROUTE', path);
    },
    setJobansichtBewerbungenReturnPath({ commit }, { path }) {
      commit('SET_JOBANSICHT_BEWERBUNGEN_RETURN_PATH', path);
    },
    setJobansichtBevorstehendeReturnPath({ commit }, { path }) {
      commit('SET_JOBANSICHT_BEVORSTEHENDE_RETURN_PATH', path);
    },
    setJobansichtDurchgefuehrteReturnPath({ commit }, { path }) {
      commit('SET_JOBANSICHT_DURCHGEFUEHRTE_RETURN_PATH', path);
    },
    updateJobStatus({ commit }, { uuid, status }) {
      commit('UPDATE_JOB_STATUS', { uuid, status });
    },
    syncJobData({ commit, dispatch }, { uuid }) {
      return new Promise((resolve, reject) => {
        api
          .getJobData(uuid)
          .then((response) => {
            const { data } = response;
            dispatch('setJob', { uuid, data });
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // syncAuthorization({ commit, dispatch }) {
    //   return new Promise((resolve, reject) => {
    //     api
    //       .getAuthorization()
    //       .then((response) => {
    //         const authorization = response.data;
    //         commit('SET_AUTHORIZATION', authorization);
    //         resolve(authorization);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    syncStammdaten({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getStammdaten()
          .then((response) => {
            const stammdaten = response.data;
            commit('SET_STAMMDATEN', stammdaten);
            resolve(stammdaten);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncSettings({ commit, dispatch }) {
      console.log('syncSettings');
      return new Promise((resolve, reject) => {
        api
          .getSettings()
          .then((response) => {
            const settings = response.data;
            commit('SET_SETTINGS', settings);
            commit('SET_ORGANISATIONEN', settings.filter.organisationen);
            resolve(settings);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncOrganisationen({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getOrganisationen()
          .then((response) => {
            const organisationen = response.data;
            commit('SET_ORGANISATIONEN', organisationen);
            resolve(organisationen);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncOrganisationenBewerbbar({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getOrganisationenBewerbbar()
          .then((response) => {
            const organisationenBewerbbar = response.data;
            commit('SET_ORGANISATIONEN_BEWERBBAR', organisationenBewerbbar);
            resolve(organisationenBewerbbar);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncOrganisationenBewerbungen({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getOrganisationenBewerbungen()
          .then((response) => {
            const organisationenBewerbungen = response.data;
            commit('SET_ORGANISATIONEN_BEWERBUNGEN', organisationenBewerbungen);
            resolve(organisationenBewerbungen);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncAnstellungen({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getAnstellungen()
          .then((response) => {
            const anstellungen = response.data;
            commit('SET_ANSTELLUNGEN', anstellungen);
            resolve(anstellungen);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncJobtypen({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getJobtypen()
          .then((response) => {
            const jobTypen = response.data;
            commit('SET_JOB_TYPEN', jobTypen);
            resolve(jobTypen);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncAusschreibungen({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .getAusschreibungen()
          .then((response) => {
            const ausschreibungen = response.data;
            commit('SET_AUSSCHREIBUNGEN', ausschreibungen);
            resolve(ausschreibungen);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncBewerbungen({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        api
          .getBewerbungen()
          .then((response) => {
            const bewerbungen = response.data;
            commit('SET_BEWERBUNGEN', bewerbungen);
            // const jobsToSync: any[] = [];
            // state.bewerbungen.forEach((job) => {
            //   jobsToSync.push(dispatch('syncJobData', { uuid: job.uuid }));
            // });
            // Promise.all(jobsToSync)
            //   .then((responses) => {
            resolve(bewerbungen);
            // })
            // .catch((error) => {
            //   reject(error);
            // });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async syncZuweisungen({ commit, dispatch, state }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!state.settings?.filter?.organisationen?.length) await dispatch('syncSettings').catch((error) => reject(error));
        api
          .getZuweisungen()
          .then((response) => {
            const zuweisungen = response.data;
            commit('SET_ZUWEISUNGEN', zuweisungen);
            // const jobsToSync: any[] = [];
            // state.zuweisungen.forEach((job) => {
            //   jobsToSync.push(dispatch('syncJobData', { uuid: job.uuid }));
            // });
            // Promise.all(jobsToSync)
            //   .then((responses) => {
            resolve(zuweisungen);
            // })
            // .catch((error) => {
            //   reject(error);
            // });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async syncErledigte({ commit, dispatch, state }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!state.settings?.filter?.organisationen?.length) await dispatch('syncSettings').catch((error) => reject(error));
        api
          .getErledigte()
          .then((response) => {
            const erledigte = response.data;
            commit('SET_ERLEDIGTE', erledigte);
            resolve(erledigte);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncArbeitszeiten({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        api
          .getArbeitszeiten()
          .then((response) => {
            const arbeitszeiten = response.data;
            commit('SET_ARBEITSZEITEN', arbeitszeiten);
            resolve(arbeitszeiten);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    syncMitgliedQualifikationen({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .getMitgliedQualifikationen()
          .then((response) => {
            const mitgliedQualifikationen = response.data;
            commit('SET_MITGLIED_QUALIFIKATIONEN', mitgliedQualifikationen);
            resolve(mitgliedQualifikationen);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {
    syncStore,
  },
  plugins: [vuexLocal.plugin],
});
