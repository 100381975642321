<template>
  <div>
    <h3>Mitteilungen</h3>
    <!-- <v-btn color="primary" :to="{name: 'route1'}">route1</v-btn>
        <v-btn color="primary" :to="{name: 'route2'}">route2</v-btn> -->
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
};
</script>
