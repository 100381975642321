<template>
  <v-layout
    row
    justify-center
  >
    <v-dialog
      :value="value"
      persistent
      content
      content-class="centered-dialog"
    >
      <v-container fill-height>
        <v-layout
          column
          justify-center
          align-center
        >
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            :color="progressColor"
          />
          <h1
            v-if="message != null"
            style="text-align: center"
          >
            {{ message }}
          </h1>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'LoadingComponent',
  props: {
    value: { type: Boolean, default: false },
    message: { type: String, default: null },
    progressColor: { type: String, default: 'purple' },
  },
  data() {
    return {};
  },
});
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
  background: #282c2dad;
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
}
</style>
