<template>
  <v-dialog
    v-if="showStammdatenDialog"
    v-model="showStammdatenDialog"
    width="500"
    fullscreen
    scrollable
    style="height: 100%"
  >
    <v-card>
      <v-card-title>Stammdaten</v-card-title>
      <v-divider />
      <v-container style="overflow-y: scroll">
        <v-text-field
          v-model="stammdaten.email"
          label="Email"
          outlined
        />
        <v-text-field
          v-model="stammdaten.vorname"
          label="Vorname"
          outlined
        />
        <v-text-field
          v-model="stammdaten.nachname"
          label="Nachname"
          outlined
        />
        <div>
          <v-text-field
            :value="geburtsdatumLeserlich"
            label="Geburtsdatum"
            append-icon="event"
            readonly
            outlined
            @click="showDatePicker"
          />

          <v-dialog
            v-model="datePicker"
            width="290px"
            persistent
          >
            <v-date-picker
              v-model="datePickerValue"
              scrollable
              locale="de-DE"
            >
              <v-spacer />
              <v-btn
                color="primary"
                @click="datePicker = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                :disabled="!datePickerValue"
                @click="datePickerSuccess()"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <v-select
          v-model="stammdaten.geschlecht"
          label="Geschlecht"
          :items="geschlechter"
          outlined
        />
        <v-text-field
          v-model="stammdaten.strasseHausnummer"
          label="Straße & Hausnummer"
          outlined
        />
        <v-text-field
          v-model="stammdaten.postleitzahl"
          label="Postleitzahl"
          outlined
        />
        <v-text-field
          v-model="stammdaten.ort"
          label="Ort"
          outlined
        />
        <v-select
          v-model="stammdaten.land"
          label="Land"
          :items="laender"
          outlined
        />
        <v-text-field
          v-model="stammdaten.telefon1"
          label="Telefon 1"
          outlined
        />
        <v-text-field
          v-model="stammdaten.telefon2"
          label="Telefon 2"
          outlined
        />
        <v-text-field
          v-model="stammdaten.kontoinhaber"
          label="Kontoinhaber"
          outlined
        />
        <v-text-field
          v-model="stammdaten.iban"
          label="IBAN"
          outlined
        />
        <v-text-field
          v-model="stammdaten.bic"
          label="BIC"
          outlined
        />
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          :disabled="!isFormValid"
          text
          @click="saveStammdaten()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="showStammdatenDialog = false"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import api from '../../api';
import formatMixin from '../../formatMixin';

export default Vue.extend({
  mixins: [formatMixin],
  data: () => ({
    showStammdatenDialog: false,
    datePicker: false,
    datePickerValue: null,
    stammdaten: {},
    geschlechter: ['männlich', 'weiblich', 'divers'],
    laender: ['Deutschland'],
  }),
  computed: {
    isFormValid() {
      return (
        this.stammdaten
        && this.stammdaten.email
        && this.stammdaten.vorname
        && this.stammdaten.nachname
        && this.stammdaten.strasseHausnummer
        && this.stammdaten.postleitzahl
        && this.stammdaten.ort
        && this.stammdaten.land
        && this.stammdaten.kontoinhaber
        && this.stammdaten.iban
        && this.stammdaten.bic
        && this.stammdaten.telefon1
        && this.stammdaten.geschlecht
        && this.stammdaten.geburtsdatum
      );
    },
    geburtsdatumLeserlich() {
      return this.stammdaten.geburtsdatum ? this.datumLeserlich(this.stammdaten.geburtsdatum) : '';
    },
  },
  mounted() {
    EventBus.$on('showStammdatenDialog', () => {
      this.stammdaten = { ...this.$store.getters.stammdaten };
      this.showStammdatenDialog = true;
    });
  },
  methods: {
    showDatePicker() {
      this.datePickerValue = this.stammdaten.geburtsdatum;
      this.datePicker = true;
    },
    datePickerSuccess() {
      this.stammdaten.geburtsdatum = this.datePickerValue;
      this.datePicker = false;
    },
    saveStammdaten() {
      this.$store.dispatch('setLoading', { state: true, message: 'Sende Stammdaten' });
      api
        .postStammdaten(this.stammdaten)
        .then(() => {
          this.$store
            .dispatch('syncStammdaten')
            .then(() => {
              this.showStammdatenDialog = false;
              EventBus.$emit('showSnackbar', 'Stammdaten aktualisiert');
            })
            .catch((error) => {
              if (error && error.response) {
                console.log(error.response);
                EventBus.$emit('showSnackbar', error.response.data.message);
              }
            });
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    allowedMinutes: (m) => m % 5 === 0,
  },
});
</script>

<style></style>
