<template>
  <div>
    <scroller
      :on-refresh="refresh"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-container>
        <div class="mb-2 d-flex">
          <h3>Meine Organisationen</h3>
          <v-btn class="ml-auto" color="#eff2f9" @click="showOrganisationBewerbungDialog"
            >Hinzufügen</v-btn
          >
        </div>
        <div v-for="organisation in organisationen" :key="organisation.tenantId">
          {{ organisation.name }}
          <ul>
            <li v-for="anstellung in orgAnstellungen(organisation.tenantId)" :key="anstellung.id">
              {{ anstellung.typ }} - ab {{ datumLeserlich(anstellung.start) }}
              <ul>
                <li v-for="stundenlohn in getStundenloehne(anstellung)" :key="stundenlohn.id">
                  {{ stundenlohn.typ }} - ab {{ datumLeserlich(stundenlohn.gueltigAb) }} - Brutto:
                  <currency-input
                    disabled
                    style="color: green; max-width: 50px"
                    :value="stundenlohn.betrag"
                    currency="EUR"
                    locale="de"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-if="organisationenBewerbungen">
          <h3>Meine Bewerbungen</h3>
          <div v-for="bewerbung in organisationenBewerbungen" :key="bewerbung.id">
            {{ bewerbung.name }}
          </div>
        </div>
      </v-container>
    </scroller>

    <v-dialog
      v-model="organisationBewerbungDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline">Bewerbung</v-card-title>
        <v-card-text>
          <v-container class="py-0">
            <p>
              Wählen Sie eine Organisation aus für die Sie arbeiten möchten. Sobald die Organisation
              ihre Bewerbung angenommen hat stehen Ihnen deren Jobs zur Auswahl.
            </p>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  :items="organisationenBewerbbar"
                  v-model="ausgewaehlteOrganisation"
                  item-value="id"
                  item-text="name"
                  label="Organisation"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="organisationBewerbungDialog = false">Abbrechen</v-btn>
          <v-btn
            color="primary"
            text
            @click="bewerbungVersenden"
            :disabled="!ausgewaehlteOrganisation"
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import formatMixin from '../../formatMixin';
import api from '../../api';
import { State } from '../../store';

export default formatMixin.extend({

  mixins: [formatMixin],
  data: () => ({
    organisationBewerbungDialog: false,
    ausgewaehlteOrganisation: null,
  }),
  computed: {
    ...mapGetters([
      // nur für vuex, andere unten mit ...obj
      // 'organisationen',
      'organisationenBewerbbar',
      // 'organisationenBewerbungen',
      // 'anstellungen',
    ]),
    organisationen(): any[] { return this.$store.getters.organisationen; },
    anstellungen(): any[] { return this.$store.getters.anstellungen; },
    organisationenBewerbungen(): any[] { return this.$store.getters.organisationenBewerbungen; },
  },
  methods: {
    refresh(done: ()=> void) {
      const syncRequests = [];
      syncRequests.push(this.$store.dispatch('syncOrganisationenBewerbungen'));
      syncRequests.push(this.$store.dispatch('syncOrganisationen'));
      syncRequests.push(this.$store.dispatch('syncAnstellungen'));

      Promise.all(syncRequests)
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          done();
        });
    },
    showOrganisationBewerbungDialog() {
      this.$store.dispatch('setLoading', { state: true, message: 'hole Daten' });
      this.$store.dispatch('syncOrganisationenBewerbbar').then(() => {
        this.ausgewaehlteOrganisation = null;
        this.organisationBewerbungDialog = true;
      }).catch((error) => {
        if (error && error.response) {
          console.log(error.response);
          EventBus.$emit('showSnackbar', error.response.data.message);
        }
      })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    bewerbungVersenden() {
      this.$store.dispatch('setLoading', { state: true, message: 'versende Bewerbung' });
      const bewerbung = { tenantId: this.ausgewaehlteOrganisation };

      api.postOrganisationBewerbung(bewerbung).then(() => {
        this.organisationBewerbungDialog = false;
        this.$store.dispatch('syncOrganisationenBewerbungen');
      }).catch((error) => {
        if (error && error.response) {
          console.log(error.response);
          EventBus.$emit('showSnackbar', error.response.data.message);
        }
      })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    orgAnstellungen(tenantId: string): any[] {
      const result = this.anstellungen.find((el: any) => String(el.tenantId) === tenantId);
      if (!result) {
        return [];
      }
      return result.anstellungen;
    },
    getStundenloehne(anstellung: any): any[] {
      return anstellung.stundenloehne;
    },
  },
});
</script>
