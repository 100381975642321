<template>
  <div>
    <scroller
      :on-refresh="refreshJobData"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <jobansicht-job-info :job="job" />
      <div class="mt-2">
        <v-btn v-if="isJobZurueckziehbar(job)" color="primary" @click="showBewerbungBeendenDialog">
          Beenden
        </v-btn>
        <v-btn v-if="!isJobZurueckziehbar(job)" color="primary" disabled>
          Bewerbung wurde beendet
        </v-btn>
      </div>
    </scroller>

    <v-dialog
      v-model="bewerbungBeendenDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Bewerbung Beenden </v-card-title>

        <v-card-text>
          Falls du deine Bewerbung beenden möchtest klicke auf bestätigen.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="bewerbungBeendenDialog = false"> Abbrechen </v-btn>
          <v-btn color="primary" text @click="bewerbungBeenden"> Senden </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import JobansichtJobInfo from '@/components/JobansichtJobInfo.vue';
import api from '@/api';
import EventBus from '@/eventBus';
import formatMixin from '../../../formatMixin';

export default formatMixin.extend({
  components: {
    'jobansicht-job-info': JobansichtJobInfo,
  },
  mixins: [formatMixin],
  data: () => ({
    bewerbungBeendenDialog: false,
    rueckzugErfolgreich: false,
  }),
  computed: {
    ...mapGetters(['bewerbungen']),
    job(): any {
      return this.$store.getters.meineJobsJobansichtJob;
    },
    jobMeta(): any {
      const { uuid } = this.job;
      const { bewerbungen } = this.$store.getters;
      const bewerbungMeta = bewerbungen.find((el: any) => el.uuid === uuid);
      return bewerbungMeta;
    },
  },
  methods: {
    showBewerbungBeendenDialog() {
      this.bewerbungBeendenDialog = true;
    },
    refreshJobData(done: ()=> void) {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    isJobZurueckziehbar(job: any) {
      const validStati = ['Ausgeschrieben', 'Ersatzsuche'];
      return (
        this.job.status && validStati.indexOf(this.job.status) !== -1 && !this.rueckzugErfolgreich
      );
    },
    bewerbungBeenden() {
      const { uuid } = this.job;
      const anstellungId = this.jobMeta.mitarbeiterAnstellungId;
      const data = {
        uuid,
        anstellungId,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Beende Bewerbung' });
      api
        .postJobBewerbungBeenden(data)
        .then(() => {
          this.rueckzugErfolgreich = true;
          this.$store.dispatch('deleteJobFromBewerbungen');
          this.bewerbungBeendenDialog = false;
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
  },
});
</script>

<style></style>
