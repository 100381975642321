import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebaseConfig from './firebase.dev.config';
// import store from './store'

// Vue.use(firebase);

const firebaseApp = initializeApp(firebaseConfig);
// console.log('init firebase');

const isAuthReady = new Promise((resolve, reject) => {
  const unsub = getAuth().onAuthStateChanged(
    () => {
      unsub();
      resolve(null);
    },
    (error) => reject(error),
  );
});

export { firebaseApp, isAuthReady };
