<template>
  <div class="home">
    <v-tabs
      v-model="activeTab"
      grow
      background-color="transparent"
      color="basil"
      height="40"
      active-class="jobansicht-tabheader"
    >
      <v-tab
        v-for="tab of tabs"
        :key="tab.index"
        class="mx-0 px-2 smalltab"
        :disabled="isTabDisabled(tab.index)"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="activeTab"
      touchless
    >
      <v-tab-item
        key="0"
        height="100px"
      >
        <div class="tab-item-wrapper">
          <jobansicht-job-tab />
        </div>
      </v-tab-item>
      <v-tab-item key="1" />
      <v-tab-item key="2" />
      <v-tab-item key="3" />
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import JobansichtJobTab from '@/components/Jobangebote/JobangeboteJobansichtJobTab.vue';

export default Vue.extend({
  components: {
    'jobansicht-job-tab': JobansichtJobTab,
  },
  data: () => ({
    activeTab: 0,
    tabs: [
      { index: 0, name: 'Job' },
      { index: 1, name: 'Details' },
      { index: 2, name: 'Teilnehmer' },
      { index: 3, name: 'Orga' },
    ],
  }),
  methods: {
    isTabDisabled(tabIndex: number) {
      return tabIndex > 0;
    },
  },
});
</script>

<style>
.smalltab {
  min-width: 10px !important;
}

.jobansicht-tabheader {
}

.tab-item-wrapper {
  height: calc(100dvh - 166px);
}

.v-slide-group__prev {
  display: none !important;
}
</style>
