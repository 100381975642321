<template>
  <div>
    <scroller
      :on-refresh="refresh"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-container>
        <div class="mb-2">
          <h3>Durchgeführt</h3>
        </div>
        <v-row>
          <v-col
            v-for="item in durchgefuehrteSortiert"
            :key="item.uuid"
            style="min-width: 146px; max-width: 219px"
            class="pa-1 flex-grow-1 flex-shrink-0"
            @click="showJob(item)"
          >
            <job-list-element :item="item" />
          </v-col>
        </v-row>
      </v-container>
    </scroller>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import JobListElement from '@/components/JobListElement.vue';
import EventBus from '@/eventBus';

export default Vue.extend({
  components: {
    'job-list-element': JobListElement,
  },
  data: () => ({}),
  computed: {
    durchgefuehrteGefiltert(): any[] {
      return this.$store.getters.durchgefuehrteGefiltert;
    },
    durchgefuehrteSortiert(): any[] {
      return this.durchgefuehrteGefiltert.slice().sort((a, b) => {
        const dateA = new Date(a.startDatum).getTime();
        const dateB = new Date(b.startDatum).getTime();
        return dateA - dateB;
      });
    },
  },
  methods: {
    refresh(done: () => void) {
      this.$store
        .dispatch('syncZuweisungen')
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    showJob(item: any) {
      const { uuid } = item;
      const path = '/meine_jobs/durchgefuehrte/jobansicht';
      this.$store.dispatch('setMeineJobsJobansichtJobUID', { uuid });
      this.$store.dispatch('setJobansichtDurchgefuehrteReturnPath', {
        path: '/meine_jobs/durchgefuehrte',
      });
      EventBus.$emit('routeToJobansicht', { uuid, path });
    },
  },
});
</script>
