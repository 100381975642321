import { render, staticRenderFns } from "./JobangeboteJobansicht.vue?vue&type=template&id=3b99f334"
import script from "./JobangeboteJobansicht.vue?vue&type=script&lang=ts"
export * from "./JobangeboteJobansicht.vue?vue&type=script&lang=ts"
import style0 from "./JobangeboteJobansicht.vue?vue&type=style&index=0&id=3b99f334&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems})
