import 'material-design-icons-iconfont/dist/material-design-icons.css';
import moment from 'moment';
import 'reflect-metadata';
import VCurrencyField from 'v-currency-field';
import Vue from 'vue';
import VueSanitize from 'vue-sanitize';
import VueScroller from 'vue-scroller';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vue2TouchEvents from 'vue2-touch-events';
import { VTextField } from 'vuetify/lib';
import App from './App.vue';
import axiosInstance from './axios';
import './firebase';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import router from './router';
import './router/route-guard';
import store from './store';

Vue.use(VueSanitize);
Vue.use(Vue2TouchEvents);

Vue.use(VueScroller);

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: false,
  defaultValue: 0,
});

moment.locale('de');
Vue.config.productionTip = false;

Vue.prototype.$http = axiosInstance;
// eslint-disable-next-line consistent-return
Vue.filter('formatDate', (value: any) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm');
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBnHd1SqLws2pwY4tAd4Xa_dD5MVMpmlqA',
    libraries: 'places',
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
