<template>
  <v-container>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Registrierung</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="email"
                prepend-icon="mail"
                name="email"
                label="Email"
                type="email"
                :rules="[rules.required]"
                required
              />
              <v-text-field
                id="passwort"
                v-model="passwort"
                prepend-icon="lock"
                name="passwort"
                label="Passwort"
                required
                :rules="[rules.required, rules.passwort]"
                :append-icon="showPasswort ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPasswort ? 'text' : 'password'"
                @click:append="showPasswort = !showPasswort"
              />
              <v-checkbox
                v-model="agbs"
                type="checkbox"
                required
                :rules="[rules.required]"
              >
                <template #label>
                  <div>
                    Ich akzeptiere die
                    <a
                      target="_blank"
                      href="https://www.freeartsofmovement.com/teilnahmebedingungen"
                      @click.stop
                    > AGBs </a>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions class="mb-12">
            <v-spacer />
            <!-- <vue-recaptcha
              ref="recaptcha"
              sitekey="6LfDH7MUAAAAAIBjfo82zoFPwgI9nPgJStTX70Zq"
              load-recaptcha-script
              badge="bottomleft"
              @verify="registerUser"
            >
              <v-btn
                color="primary"
                :disabled="!valid"
              >
                Registrieren
              </v-btn>
            </vue-recaptcha> -->
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="registerUser"
              >
                Registrieren
              </v-btn>
            <v-btn
              class="ml-2"
              color="secondary"
              @click="unshowRegister"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
// import { VueRecaptcha } from 'vue-recaptcha';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import EventBus from '../eventBus';

export default Vue.extend({
  name: 'RegisterComponent',
  components: {
    // VueRecaptcha,
  },
  data: () => ({
    valid: false,
    email: '',
    passwort: '',
    agbs: false,
    rules: {
      email: (v: string) => /.+@.{2,}\..{2,}/.test(v) || 'Ungültige Email',
      required: (v: boolean) => !!v || 'Pflichtfeld',
      passwort: (v: string) => (v && v.length >= 6) || 'Passwort muss mindestens 6 Zeichen lang sein',
    },
    menu2: false,
    showPasswort: false,
  }),
  methods: {
    async registerUser() {
      // https://stackoverflow.com/questions/52109471/typescript-in-vue-property-validate-does-not-exist-on-type-vue-element
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        const data = {
          email: this.email,
          passwort: this.passwort,
          // recaptchaToken: token,
        };

        this.$store.dispatch('setLoading', { state: true, message: 'Sende Registrierung' });
        try {
          const auth = getAuth();
          // eslint-disable-next-line max-len
          const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.passwort);
          const { user } = userCredential;
          console.log(user);
          this.$store.dispatch('userLogin', { userId: user.uid });
          this.unshowRegister();
        } catch (error: any) {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
        } finally {
          this.$store.dispatch('setLoading', { state: false });
        }
      }
    },
    unshowRegister() {
      EventBus.$emit('unshowRegister');
    },
  },
});
</script>
