import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  methods: {
    datumLeserlichKurz(jsDate: any) {
      return moment(jsDate).format('DD. MMM');
    },
    datumLeserlich(jsDate: any) {
      return moment(jsDate).format('DD. MMMM YYYY');
    },
    // rundet eine Zahl auf eine bestimmte Anzahl von Dezimalstellen
    round(value_: number, stellen: number) {
      if (typeof stellen === 'undefined' || +stellen === 0) {
        return Math.round(value_);
      }

      let value = +value_;
      // eslint-disable-next-line no-param-reassign
      stellen = +stellen;

      if (Number.isNaN(value) || !(typeof stellen === 'number' && stellen % 1 === 0)) {
        return NaN;
      }
      // Shift
      const parts = value.toString().split('e');
      value = Math.round(+(`${parts[0]}e${parts[1] ? (+parts[1] + stellen) : stellen}`));

      // Shift back
      const partsBack = value.toString().split('e');
      return +(`${partsBack[0]}e${partsBack[1] ? (+partsBack[1] - stellen) : -stellen}`);
    },
    // wandelt engl Zahl in deutsche Währung
    // zB 12345.0 in 12.345,00
    numberToGerman(number_: number) {
      const number = this.round(number_, 2);
      const parts = number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      parts[1] = (`${parts[1] || ''}00`).slice(0, 2);
      return parts.join(',');
    },
  },
});
