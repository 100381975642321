<template>
  <div>
    <div class="d-flex">
      <div class="mt-5 mr-1">
        <v-img
          :src="organisationsBild(job.tenantId)"
          contain
          height="30"
          width="30"
          min-width="30"
        />
      </div>
      <h1
        class="mb-1 mt-6"
        style="line-height: 20px"
      >
        {{ job.type }}
      </h1>
    </div>
    <h3 class="blue--text mb-1">
      {{ getSportNameOrEmpty(job) }}
    </h3>
    <v-row
      v-for="jobTime in jobTimes"
      :key="jobTime.uuid"
    >
      <v-col
        cols="4"
        class="py-1"
      >
        <v-icon color="blue">
          mdi-calendar
        </v-icon>
        <span>{{ datumLeserlichKurz(jobTime.date) }}</span>
      </v-col>
      <v-col
        cols="5"
        class="py-1"
      >
        <v-icon color="green">
          mdi-clock-outline
        </v-icon>
        <span>{{ jobTime.start }}-{{ jobTime.end }}</span>
      </v-col>
      <!-- <v-col
        v-if="jobTagHatPause(jobTime)"
        cols="3"
        class="py-1"
      >
        <v-icon color="blue">
          mdi-timer-sand
        </v-icon>
        <span>{{ jobTag.pause }}</span>
      </v-col> -->
    </v-row>
    <div class="mt-1 d-flex justify-space-between">
      <div>
        <v-icon color="red">
          mdi-map-marker
        </v-icon>
        <span>{{ job.site.name }}</span>
      </div>
      <!-- <div v-if="hasJobLohn">
        <v-icon color="dark-green">
          mdi-currency-eur
        </v-icon>
        <span>{{ numberToGerman(job.jobLohn) }}</span>
      </div> -->
    </div>
    <div class="mt-3 d-flex justify-start">
      <v-chip
        v-if="job.teilnehmerAnzahl"
        class="mr-1"
      >
        {{ job.teilnehmerAnzahl }} Anmeldungen
      </v-chip>
      <v-chip
        v-if="job.teilnehmerAlter"
        class="mr-1"
      >
        ca. {{ job.teilnehmerAlter }} Jahre
      </v-chip>
      <!-- <v-chip
        v-if="job.jobAnzahl"
        class="mr-1"
      >
        {{ job.numberOfNeededExecuters }} Trainer
      </v-chip> -->
     </div>
    <h3
      v-if="job.description"
      class="mt-4"
    >
      Beschreibung
    </h3>
    <v-card
      v-if="job.description"
      color="#e0e0e0"
      class="pa-2"
    >
      {{ job.description }}
    </v-card>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import formatMixin from '../formatMixin';
import Job from '../classes/job';

export default formatMixin.extend({
  mixins: [formatMixin],
  props: { job: { type: Object as () => Job } },
  computed: {
    ...mapGetters([
      // nur für vuex, andere unten mit ...obj
      'organisationen',
    ]),
    ...mapGetters({
      getJobDoc: 'syncStore/getJobDoc',
    }),
    jobTimes(): any[] {
      return this.job.jobTimes;
    },
  },
  methods: {
    getSportNameOrEmpty(job: any): string {
      const { sport } = job;
      return sport;
    },
    jobTagHatPause(jobTag: any): boolean {
      return false;
      // need to be fixed, job hat nun mehrere Zeiten
      // if (!jobTag.Pause) {
      //   return false;
      // }
      // return jobTag.pause.split(':').some((el: any) => Number.parseInt(el, 10) > 0);
    },
    organisationsBild(tenantId: string): string {
      const org = this.organisationen.find((el: any) => el.tenantId === tenantId);
      if (org) {
        return org.logoURL;
      }
      return 'https://99designs-blog.imgix.net/blog/wp-content/uploads/2017/12/rainbow-app-icon.png';
    },
  },
  mounted() {
    const docId = this.job.uuid;
    if (!this.getJobDoc) {
      const data = { teilnehmer: {} };
      this.job.anmeldungen.forEach((anmeldung: any) => {
        (data.teilnehmer as any)[anmeldung.uuid as string] = { anwesend: false };
      });
      this.$store.dispatch('syncStore/createJobDoc', { docId, data });
    }
  },
});
</script>

<style></style>
