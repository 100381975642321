<template>
  <v-app :class="isIOSDevice() ? 'ios' : 'non-ios'">
    <v-app-bar app>
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-toolbar-title class="headline text-uppercase">
        <!-- <span>Trainer App</span>
        <span> - </span> -->
        <span class="font-weight-light">{{ pageTitle }}</span>
      </v-toolbar-title>
      <!-- <v-progress-linear
        :active="isLoading"
        indeterminate
        absolute
        bottom
        color="black accent-4"
      ></v-progress-linear> -->

      <v-spacer />

      <v-toolbar-items>
        <v-btn v-if="showMenuSaveButton" text @click="saveFilterSettings"> Speichern </v-btn>
        <v-btn v-if="showMenuFilterButton" icon>
          <v-icon @click.native.stop="showFilterView"> mdi-filter </v-icon>
        </v-btn>
        <v-btn v-if="showMenuCloseButton" icon>
          <v-icon @click.native.stop="closeCurrentView"> mdi-close-circle </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-content style="background-color: white; min-height: -webkit-fill-available">
      <v-container fluid>
        <LoginComponent v-if="!isAuthenticated && !showRegister && !showPasswordReset" />
        <RegisterComponent v-else-if="!isAuthenticated && showRegister" />
        <PasswordResetComponent v-else-if="!isAuthenticated && showPasswordReset" />
        <keep-alive v-else>
          <router-view />
        </keep-alive>
      </v-container>
    </v-content>

    <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
      <div v-show="false" class="v-menu__content--active" />
      <!-- bugfix da snackbar sonst dialog schliesst -->
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.visible = false"> Close </v-btn>
    </v-snackbar>

    <!-- <v-dialog v-model="isLoading" persistent fullscreen content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate :size="70" :width="7" color="purple">
            </v-progress-circular>
          </v-layout>
        </v-container>
    </v-dialog> -->
    <LoadingComponent style="display: none" :value="isLoading" :message="loadingMessage" />

    <v-bottom-navigation
      v-if="showBottomNavigation"
      :input-value="showNav"
      color="indigo"
      grow
      fixed
      app
    >
      <v-btn
        exact
        value="mitteilungen"
        :to="{ path: lastMitteilungenRoute }"
        :disabled="isBottomNavigationDisabled"
        :class="{ 'v-btn--active': isMitteilungenDomain }"
      >
        <v-icon>home</v-icon>
      </v-btn>
      <v-btn
        value="meine_jobs"
        :to="{ path: lastMeineJobsRoute }"
        :disabled="isBottomNavigationDisabled"
        :class="{ 'v-btn--active': isMeineJobsDomain }"
      >
        <v-icon>notifications</v-icon>
      </v-btn>
      <v-btn
        value="jobangebote"
        :to="{ path: lastJobangeboteRoute }"
        :disabled="isBottomNavigationDisabled"
        :class="{ 'v-btn--active': isJobangeboteDomain }"
      >
        <v-icon>show_chart</v-icon>
      </v-btn>
      <v-btn
        value="settings"
        :to="{ path: lastSettingsRoute }"
        :disabled="isBottomNavigationDisabled"
        :class="{ 'v-btn--active': isSettingsDomain }"
      >
        <v-icon>settings</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <SyncConflictDialog />
    <FilterDialog />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import EventBus from './eventBus';
import LoginComponent from './components/Login.vue';
import RegisterComponent from './components/Register.vue';
import PasswordResetComponent from './components/PasswordReset.vue';
import LoadingComponent from './components/Loading.vue';
import FilterDialog from './views/FilterDialog.vue';
import SyncConflictDialog from './components/SyncConflictDialog.vue';

export default Vue.extend({
  name: 'App',
  components: {
    LoginComponent,
    RegisterComponent,
    PasswordResetComponent,
    LoadingComponent,
    SyncConflictDialog,
    FilterDialog,
  },
  data: () => ({
    drawer: null,
    snackbar: {
      visible: false,
      text: '',
      timeout: 3000,
      color: '',
    },
    showSnackbar: false,
    snackbarText: 'hans',
    showNav: true,
    showRegister: false,
    showPasswordReset: false,
  }),
  computed: {
    ...mapGetters([
      // nur für vuex, andere unten mit ...obj
      'isAuthenticated',
      'isLoading',
      'loadingMessage',
      'lastMitteilungenRoute',
      'lastMeineJobsRoute',
      'lastJobangeboteRoute',
      'lastSettingsRoute',
      'jobansichtBewerbungenReturnPath',
      'jobansichtBevorstehendeReturnPath',
      'jobansichtDurchgefuehrteReturnPath',
      'jobs',
    ]),
    ...mapGetters({
      currentConflict: 'syncStore/getCurrentConflict',
    }),
    pageTitle(): string | null | undefined {
      if (this.$store.getters.isAuthenticated) {
        if (this.$route.meta && this.$route.meta.title) {
          return this.$route.meta.title;
        }
        return this.$route.name;
      }
      return 'Trainer App';
    },
    showBottomNavigation(): boolean {
      // const routesWithoutBottomNavigation = [];
      // return routesWithoutBottomNavigation.indexOf(this.$route.name) === -1;
      return true;
    },
    isBottomNavigationDisabled(): boolean {
      if (!this.isAuthenticated) return true;
      const routesWithoutBottomNavigation = ['filter', 'jobbewerbungen'];
      return routesWithoutBottomNavigation.includes(this.$route.name ?? '');
    },
    showMenuCloseButton(): boolean {
      const closeableViews = [
        'jobangebote - jobansicht',
        'meine jobs - bewerbungen',
        'bewerbungen - jobansicht',
        'meine jobs - bevorstehende',
        'meine jobs - durchgefuehrte',
        'bevorstehende - jobansicht',
        'durchgefuehrte - jobansicht',
        'stammdaten',
        'organisationen',
        'arbeitszeiten',
        'archiv',
      ];
      return closeableViews.some((el) => el === this.$route.name);
    },
    showMenuFilterButton(): boolean {
      const filterableViews = ['meine jobs', 'jobangebote'];
      return filterableViews.some((el) => el === this.$route.name);
    },
    showMenuSaveButton(): boolean {
      const filterableViews = ['filter'];
      return filterableViews.some((el) => el === this.$route.name);
    },
    isMitteilungenDomain(): boolean {
      return this.$route.path.startsWith('/mitteilungen');
    },
    isMeineJobsDomain(): boolean {
      return this.$route.path.startsWith('/meine_jobs');
    },
    isJobangeboteDomain(): boolean {
      return this.$route.path.startsWith('/jobangebote');
    },
    isSettingsDomain(): boolean {
      return this.$route.path.startsWith('/settings');
    },
  },
  mounted() {
    console.log('well hello sunshine');
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch('syncStore/setupStore');
    }

    EventBus.$on('showRegister', () => {
      this.showRegister = true;
    });
    EventBus.$on('unshowRegister', () => {
      this.showRegister = false;
    });
    EventBus.$on('showPasswordReset', () => {
      this.showPasswordReset = true;
    });
    EventBus.$on('unshowPasswordReset', () => {
      this.showPasswordReset = false;
    });
    EventBus.$on('showSnackbar', (payload: any) => {
      this.snackbar.visible = false;
      if (payload.error) {
        this.snackbar.color = 'error';
        this.snackbar.timeout = 0;
        this.snackbar.text = payload.text;
      } else {
        this.snackbar.color = '';
        this.snackbar.timeout = 3000;
        this.snackbar.text = payload;
      }
      this.snackbar.visible = true;
    });
    EventBus.$on('setupSyncStore', () => {
      this.$store.dispatch('syncStore/setupStore');
    });
    EventBus.$on('logout', () => {
      this.$store.dispatch('userLogout');
    });

    /*
            routed zur jobansicht falls job daten vorhanden/ladbar sind
            gibt sonst Fehlermeldung zurück
        */
    EventBus.$on('routeToJobansicht', (payload: any) => {
      const { uuid, path } = payload;
      if (
        !this.jobs[uuid]
        || (this.jobs[uuid]
          && !this.jobs[uuid].isJobMirZugesagt
          && (path === '/meine_jobs/bevorstehende/jobansicht'
            || path === '/meine_jobs/durchgefuehrte/jobansicht'))
      ) {
        this.$store.dispatch('setLoading', { state: true, message: 'Hole Job Daten' });
        this.$store
          .dispatch('syncJobData', { uuid })
          .then(() => {
            this.$router.push({ path }).catch((err) => {
              console.log(err);
            });
          })
          .catch((error) => {
            if (error && error.response) {
              // EventBus.$emit('showSnackbar', error);
            }
          })
          .finally(() => {
            this.$store.dispatch('setLoading', { state: false });
          });
      } else {
        this.$router.push({ path }).catch((err) => {
          console.log(err);
        });
      }
    });

    EventBus.$on('refreshLists', () => {
      this.$store.dispatch('fetchOperatingData');
      if (this.currentConflict) {
        EventBus.$emit('showSyncConflictDialog'); // auslagern ???? <--------------------------------------------
      }
    });

    EventBus.$on('closeCurrentView', () => {
      // dies ist nur eine vorrübergehende Lösung und muss evtl angepasst werden
      const route = this.$router.currentRoute;
      let path = null;
      if (route.path === '/meine_jobs/bewerbungen/jobansicht') {
        path = this.jobansichtBewerbungenReturnPath;
      } else if (route.path === '/meine_jobs/bevorstehende/jobansicht') {
        path = this.jobansichtBevorstehendeReturnPath;
      } else if (route.path === '/meine_jobs/durchgefuehrte/jobansicht') {
        path = this.jobansichtDurchgefuehrteReturnPath;
      } else {
        // weitere return pathes hier mit else if hinzufügen
        path = route.matched[0].path;
      }
      this.$router.push({ path });
    });

    EventBus.$on('navigate', (payload: any) => {
      this.$router.push(payload);
    });
  },
  methods: {
    closeCurrentView(): void {
      EventBus.$emit('closeCurrentView');
    },
    showFilterView(): void {
      // this.$router.push({ name: 'filter' })
      EventBus.$emit('showFilterDialog');
    },
    saveFilterSettings(): void {
      EventBus.$emit('saveFilterSettings', () => {
        this.$router.go(-1);
      });
    },
    isIOSDevice(): boolean {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    },
  },
});
</script>
<style>
.grecaptcha-badge {
  bottom: 70px !important;
}

html {
  /* Disables pull-to-refresh and overscroll glow effect.
     Still keeps swipe navigations. */
  overscroll-behavior-y: none !important;
}

.loading-dialog {
  background-color: #30303050;
}

.v-item-group.v-bottom-navigation .v-btn.v-size--default {
    height: inherit;
}

.ios .v-application--wrap {
  min-height: initial;
  max-height: 100%;
}
</style>
