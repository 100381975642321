<template>
  <v-dialog
    v-model="showSyncConflictDialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title
      >
        Sync Conflict
      </v-card-title>

      <v-card-text>
        <p>
          Betroffenes Dokument: <b>{{ currentConflictId }}</b>
        </p>
        <p>Es gibt einen Konflikt beim Synchronisieren des Dokuments.</p>
        <p><b>Wählen Sie welche Version verwendet werden soll:</b></p>

        <v-tabs
          v-model="syncTab"
          background-color="transparent"
          color="basil"
          grow
        >
          <v-tab
            key="local"
            class="mx-0"
          >
            Lokal
          </v-tab>
          <v-tab key="server">
            Server
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="syncTab">
          <v-tab-item key="local">
            <v-card
              flat
              color="basil"
              style="max-height: 250px; overflow: scroll"
            >
              <v-card-text>{{ getConflictLocalVersion }}</v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="server">
            <v-card
              flat
              color="basil"
              style="max-height: 250px; overflow: scroll"
            >
              <v-card-text>{{ getConflictServerVersion }}</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="handleSyncConflict"
        >
          Auswahl Verwenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import EventBus from '../eventBus';

export default Vue.extend({
  name: 'SyncConflictDialog',
  components: {},
  data: () => ({
    showSyncConflictDialog: false,
    conflictDoc: 'Job 301',
    syncTab: null,
  }),
  computed: {
    ...mapGetters({
      currentConflict: 'syncStore/getCurrentConflict',
    }),
    getConflictLocalVersion(): any {
      const conflict = this.currentConflict;
      if (!conflict) {
        return null;
      }
      if (conflict.isConflictWinnerLocal) {
        return conflict.doc.data;
      }
      return conflict.conflictingDoc.data;
    },
    getConflictServerVersion(): any {
      const conflict = this.currentConflict;
      if (!conflict) {
        return null;
      }
      if (conflict.isConflictWinnerLocal) {
        return conflict.conflictingDoc.data;
      }
      return conflict.doc.data;
    },
    currentConflictId(): string {
      // eslint-disable-next-line no-underscore-dangle
      return this.currentConflict ? this.currentConflict.doc._id : null;
    },
  },
  mounted() {
    EventBus.$on('showSyncConflictDialog', () => {
      this.showSyncConflictDialog = true;
    });
  },
  methods: {
    handleSyncConflict() {
      const keepLocal = this.syncTab === 0;
      if (keepLocal) {
        if (this.currentConflict.isConflictWinnerLocal) {
          // do nothing, already applied
        } else {
          const localDoc = this.currentConflict.conflictingDoc;
          this.$store.dispatch('syncStore/updateFirstDoc', { doc: localDoc });
          console.log('renewing to:', localDoc);
        }
      } else if (!this.currentConflict.isConflictWinnerLocal) {
        // do nothing, already applied
      } else {
        const remoteDoc = this.currentConflict.conflictingDoc;
        this.$store.dispatch('syncStore/updateFirstDoc', { doc: remoteDoc });
        console.log('renewing to:', remoteDoc);
      }
      this.$store.dispatch('syncStore/deleteCurrentConflict');
      if (!this.currentConflict) {
        this.showSyncConflictDialog = false;
      }
    },
  },
});
</script>
