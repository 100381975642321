<template>
  <div>
    <scroller
      :on-refresh="refresh"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-container>
        <div class="mb-6">
          <div class="d-flex mb-2">
            <h3>Bewerbungen</h3>
            <v-btn
              class="ml-auto flo-button"
              color="#eff2f9"
              :to="{ path: '/meine_jobs/bewerbungen' }"
              disabled
            >
              Alle anzeigen
            </v-btn>
          </div>
          <v-row>
            <v-col
              v-for="item in bewerbungenSortiert"
              :key="item.uuid"
              style="min-width: 146px; max-width: 219px"
              class="pa-1 flex-grow-1 flex-shrink-0"
              @click="showBewerbung(item)"
            >
              <job-list-element :item="item" />
            </v-col>
          </v-row>
        </div>
        <div class="mb-6">
          <div class="d-flex mb-2">
            <h3>Bevorstehend</h3>
            <v-btn
              class="ml-auto flo-button"
              color="#eff2f9"
              :to="{ path: '/meine_jobs/bevorstehende' }"
            >
              Alle anzeigen
            </v-btn>
          </div>
          <v-row>
            <v-col
              v-for="item in bevorstehendeSortiert"
              :key="item.uuid"
              style="min-width: 146px; max-width: 219px"
              class="pa-1 flex-grow-1 flex-shrink-0"
              @click="showBevorstehend(item)"
            >
              <job-list-element :item="item" />
            </v-col>
          </v-row>
        </div>
        <div class="mb-6">
          <div class="d-flex mb-2">
            <h3>Arbeitszeiten</h3>
            <v-btn
              class="ml-auto flo-button"
              color="#eff2f9"
              :to="{ path: '/meine_jobs/arbeitszeiten' }"
              disabled
            >
              Alle anzeigen
            </v-btn>
          </div>
          <v-row class="d-flex">
            <v-btn
              class="mx-4 my-2 flex-grow-1"
              style="color: white"
              color="#343434"
              @click="showArbeitszeitenDialog"
              disabled
            >
              Trage deine Stunden ein
            </v-btn>
          </v-row>
        </div>
        <div class="mb-4">
          <div class="d-flex mb-2">
            <h3>Durchgeführt</h3>
            <v-btn
              class="ml-auto flo-button"
              color="#eff2f9"
              :to="{ path: '/meine_jobs/durchgefuehrte' }"
            >
              Alle anzeigen
            </v-btn>
          </div>
          <v-row>
            <v-col
              v-for="item in durchgefuehrteSortiert"
              :key="item.uuid"
              style="min-width: 146px; max-width: 219px"
              class="pa-1 flex-grow-1 flex-shrink-0"
              @click="showDurchgefuehrt(item)"
            >
              <job-list-element :item="item" />
            </v-col>
          </v-row>
        </div>
        <div class="mb-6">
          <v-row class="d-flex justify-center">
            <v-btn
              class="mx-4 my-2"
              :to="{ path: '/meine_jobs/archiv' }"
              style="color: white"
              color="#185cce"
            >
              Archiv
            </v-btn>
          </v-row>
        </div>
      </v-container>
    </scroller>
    <arbeitszeiten-dialog />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import JobListElement from '@/components/JobListElement.vue';
import EventBus from '@/eventBus';
import ArbeitszeitenDialog from '@/views/MeineJobs/Arbeitszeiten/ArbeitszeitenDialog.vue';

export default Vue.extend({
  components: {
    'job-list-element': JobListElement,
    'arbeitszeiten-dialog': ArbeitszeitenDialog,
  },
  data: () => ({}),
  computed: {
    bewerbungenGefiltert(): any[] {
      return this.$store.getters.bewerbungenGefiltert;
    },
    bewerbungenSortiert(): any[] {
      return this.bewerbungenGefiltert
        .slice()
        .sort((a, b) => {
          const dateA = new Date(a.startDate).getTime();
          const dateB = new Date(b.startDate).getTime();
          return dateA - dateB;
        })
        .slice(0, 2);
    },
    bevorstehendeGefiltert(): any[] {
      return this.$store.getters.bevorstehendeGefiltert;
    },
    bevorstehendeSortiert(): any[] {
      return this.bevorstehendeGefiltert
        .slice()
        .sort((a, b) => {
          const dateA = new Date(a.startDate).getTime();
          const dateB = new Date(b.startDate).getTime();
          return dateA - dateB;
        })
        .slice(0, 2);
    },
    durchgefuehrteGefiltert(): any[] {
      return this.$store.getters.durchgefuehrteGefiltert;
    },
    durchgefuehrteSortiert(): any[] {
      return this.durchgefuehrteGefiltert
        .slice()
        .sort((a, b) => {
          const dateA = new Date(a.startDate).getTime();
          const dateB = new Date(b.startDate).getTime();
          return dateA - dateB;
        })
        .slice(0, 2);
    },
  },
  methods: {
    refresh(done: () => void) {
      const syncRequests = [];
      // syncRequests.push(this.$store.dispatch('syncBewerbungen'));
      syncRequests.push(this.$store.dispatch('syncZuweisungen'));
      syncRequests.push(this.$store.dispatch('syncErledigte'));
      Promise.all(syncRequests)
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => {
          done();
        });
    },
    showBewerbung(item: any) {
      const { uuid } = item;
      const path = '/meine_jobs/bewerbungen/jobansicht';
      this.$store.dispatch('setMeineJobsJobansichtJobUID', { uuid });
      this.$store.dispatch('setJobansichtBewerbungenReturnPath', { path: '/meine_jobs' });
      EventBus.$emit('routeToJobansicht', { uuid, path });
    },
    showBevorstehend(item: any) {
      const { uuid } = item;
      const path = '/meine_jobs/bevorstehende/jobansicht';
      this.$store.dispatch('setMeineJobsJobansichtJobUID', { uuid });
      this.$store.dispatch('setJobansichtBevorstehendeReturnPath', { path: '/meine_jobs' });
      EventBus.$emit('routeToJobansicht', { uuid, path });
    },
    showDurchgefuehrt(item: any) {
      const { uuid } = item;
      const path = '/meine_jobs/durchgefuehrte/jobansicht';
      this.$store.dispatch('setMeineJobsJobansichtJobUID', { uuid });
      this.$store.dispatch('setJobansichtDurchgefuehrteReturnPath', { path: '/meine_jobs' });
      EventBus.$emit('routeToJobansicht', { uuid, path });
    },
    showArbeitszeitenDialog() {
      EventBus.$emit('showArbeitszeitenDialog');
    },
    routeToDurchgefuehrte() {
      this.$router.push('/meine_jobs/durchgefuehrte');
    },
    routeToArbeitszeiten() {
      this.$router.push('/meine_jobs/arbeitszeiten');
    },
  },
});
</script>

<style>
.flo-button {
  height: 22px !important;
  min-width: 80px !important;
  margin-top: 3px !important;
  font-size: 12px !important;
  padding: 2px 7px 2px 7px !important;
}
</style>
