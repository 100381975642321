<template>
  <div
    class="settings"
    style="overflow-y: default"
  >
    <h3>Deine Daten</h3>
    <v-row class="d-flex">
      <v-btn
        class="mx-4 my-2 flex-grow-1"
        style="color: white"
        color="#343434"
        :to="{ path: '/settings/stammdaten' }"
        disabled
      >
        Stammdaten
      </v-btn>
    </v-row>
    <v-row class="d-flex">
      <v-btn
        class="mx-4 my-2 flex-grow-1"
        style="color: white"
        color="#343434"
        :to="{ path: '/settings/organisationen' }"
        disabled
      >
        Organisationen
      </v-btn>
    </v-row>
    <v-row class="d-flex">
      <v-btn
        class="mx-4 my-2 flex-grow-1"
        style="color: white"
        color="#343434"
        @click="passwortAendern()"
        disabled
      >
        Passwort ändern
      </v-btn>
    </v-row>
    <v-row class="d-flex">
      <v-btn
        class="mx-4 my-2 flex-grow-1"
        style="color: white"
        color="#343434"
        @click="logout"
      >
        Logout
      </v-btn>
    </v-row>
    <!-- <v-btn color="primary" @click="deleteFirstDoc">deleteFirstDoc</v-btn> -->
    <passwort-dialog />
  </div>
</template>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import PasswortDialog from '@/views/Settings/PasswortDialog.vue';

export default Vue.extend({
  components: {
    'passwort-dialog': PasswortDialog,
  },
  data: () => ({}),
  methods: {
    logout() {
      this.$store.dispatch('userLogout');
    },
    passwortAendern() {
      EventBus.$emit('showPasswortDialog');
    },
    // deleteFirstDoc () {
    //     this.$store.dispatch('syncStore/deleteFirstDoc')
    // },
    // showConflictDialog () {
    //     EventBus.$emit('showSyncConflictDialog')
    // }
  },
});
</script>
