<template>
  <div>
    <scroller
      :on-refresh="refreshJobData"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <v-card flat color="basil">
        <v-row>
          <v-col cols="6">
            <GmapMap
              :center="mapCenter"
              :zoom="17"
              map-type-id="terrain"
              style="width: 100%; height: 100px"
            >
              <GmapMarker
                v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                :draggable="false"
              />
            </GmapMap>
          </v-col>
          <v-col cols="6">
            <h4>{{ job.site.name }}</h4>
            <p>{{ job.site.address.streetAndNumber }}</p>
          </v-col>
        </v-row>
        <h2>Kontakte</h2>
        <!-- <div v-if="job.mitarbeiterKontakt" class="mb-1">
          <v-btn
            color="primary"
            disabled
            class="mr-1"
            style="background-color: black !important; color: white !important"
          >
            Büro
          </v-btn>
          <v-btn
            v-if="job.mitarbeiterKontakt.telefon1"
            class="mr-1"
            color="primary"
            :href="'tel:' + job.mitarbeiterKontakt.telefon1"
          >
            Anrufen
          </v-btn>
          <v-btn
            v-if="job.mitarbeiterKontakt.email"
            class="mr-1"
            color="primary"
            :href="'mailto:' + job.mitarbeiterKontakt.email"
          >
            Mail
          </v-btn>
        </div>
        <div v-if="job.kundeKontakt" class="mb-1">
          <v-btn
            color="primary"
            disabled
            class="mr-1"
            style="background-color: black !important; color: white !important"
          >
            Kunde
          </v-btn>
          <v-btn
            v-if="job.kundeKontakt.telefon1"
            class="mr-1"
            color="primary"
            :href="'tel:' + job.kundeKontakt.telefon1"
          >
            Anrufen
          </v-btn>
          <v-btn
            v-if="job.kundeKontakt.email"
            class="mr-1"
            color="primary"
            :href="'mailto:' + job.kundeKontakt.email"
          >
            Mail
          </v-btn>
        </div> -->
      </v-card>
    </scroller>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import EventBus from '@/eventBus';
import Job from '../classes/job';

export default Vue.extend({
  components: {},
  props: { job: { type: Object as () => Job } },
  data: (): { markers: { position: { lat: number; lng: number }}[] } => ({
    markers: [
      //
    ],
  }),
  computed: {
    mapCenter(): {lat: number; lng: number } {
      if (this.markers.length > 0) {
        return (this.markers[0] as any).position;
      }
      return { lat: 0, lng: 0 };
    },
    mitarbeiterKontakt(): any {
      return this.job.mitarbeiterKontakt;
    },
  },
  mounted() {
    if (this.job && this.job.site) {
      const lng = this.job.site.laengengrad;
      const lat = this.job.site.breitengrad;
      if (lat && lng) {
        const marker = { position: { lat, lng } };
        (this.markers as any[]).push(marker);
      }
    }
  },
  methods: {
    refreshJobData(done: () => void) {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
  },
});
</script>

<style></style>
