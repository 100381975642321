<template>
  <div>
    <scroller
      :on-refresh="refreshJobData"
      refresh-text="pull to refresh"
      release-text="release to refresh"
    >
      <jobansicht-job-info :job="job" />
      <div class="mt-2">
        <v-btn v-if="isJobBewerbbar(job)" color="primary" @click="showBewerbenDialog">
          Bewerben
        </v-btn>
        <v-btn v-if="!isJobBewerbbar(job)" color="primary" disabled>
          Bewerbung wurde versendet
        </v-btn>
      </div>
    </scroller>

    <v-dialog
      v-model="bewerbungDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline"> Bewerbung </v-card-title>

        <v-card-text>
          Wähle die Anstellung und die Priorität mit der du diesen Job haben möchtest!
        </v-card-text>

        <v-container class="py-0">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="bewerbung.anstellungId"
                :items="orgAnstellungen"
                item-value="id"
                item-text="typ"
                label="Anstellung"
                required
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="bewerbung.prioritaetId"
                :items="prioritaeten"
                item-value="id"
                item-text="text"
                label="Priorität"
                required
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="bewerbungDialog = false"> Abbrechen </v-btn>
          <v-btn color="blue darken-1" text @click="versendeBewerbung"> Senden </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import JobansichtJobInfo from '@/components/JobansichtJobInfo.vue';
import EventBus from '@/eventBus';
import api from '@/api';
import formatMixin from '../../formatMixin';

export default formatMixin.extend({
  components: {
    'jobansicht-job-info': JobansichtJobInfo,
  },
  mixins: [formatMixin],
  data: () => ({
    bewerbung: {
      prioritaetId: 1,
      anstellungId: null,
    },
    prioritaeten: [
      {
        id: 0,
        text: 'Sehr gerne',
      },
      {
        id: 1,
        text: 'Normal',
      },
      {
        id: 2,
        text: 'Wenns sein muss',
      },
    ],
    bewerbungDialog: false,
    bewerbungErfolgreich: false,
  }),
  computed: {
    job(): any {
      return this.$store.getters.jobangeboteJob;
    },
    anstellungen(): any[] {
      return this.$store.getters.anstellungen;
    },
    orgAnstellungen(): any[] {
      if (!this.anstellungen) { return []; }
      const result = this.anstellungen.find((el) => el.tenantId === this.job.tenantId);
      if (!result) { return []; }
      return result.anstellungen;
    },
  },
  methods: {
    showBewerbenDialog() {
      if (!this.orgAnstellungen || (this.orgAnstellungen && this.orgAnstellungen.length === 0)) {
        EventBus.$emit(
          'showSnackbar',
          'Es existiert keine Anstellung mit der Sie sich bewerben könnten',
        );
      } else if (this.orgAnstellungen && this.orgAnstellungen.length > 0) {
        // oder lieber in mounted?
        this.bewerbung.anstellungId = this.orgAnstellungen[0].id;
        this.bewerbungDialog = true;
      }
    },
    refreshJobData(done: ()=> void) {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    isJobBewerbbar(job: any) {
      const validStati = ['Ausgeschrieben', 'Ersatzsuche'];
      return (
        this.job.status && validStati.indexOf(this.job.status) !== -1 && !this.bewerbungErfolgreich
      );
    },
    versendeBewerbung() {
      const { uuid } = this.job;
      const { anstellungId } = this.bewerbung;
      const { prioritaetId } = this.bewerbung;

      const bewerbung = {
        uuid,
        anstellungId,
        prioritaetId,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Sende Bewerbung' });
      api
        .postJobBewerbung(bewerbung)
        .then(() => {
          this.bewerbungErfolgreich = true;
          this.$store.dispatch('deleteJobFromAusschreibungen');
          this.$store.dispatch('syncBewerbungen');
          this.bewerbungDialog = false;
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', error.response.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
  },
});
</script>

<style></style>
