<template>
  <div style="overflow-y: scroll; height: calc(100dvh - 164px);">
    <!-- vorrübergehender fix da scrollen nicht richtig funzt mit textfeldern -->
    <v-card
      flat
      color="basil"
    >
      <v-container fluid>
        <div v-if="isJobtypTraining">
          <v-text-field
            label="Teilnehmeranzahl"
            disabled
            outlined
            :value="trainingTeilnehmeranzahl"
          />
          <!-- <v-currency-field
            label="Zahlungseingänge"
            disabled
            suffix="€"
            outlined
            :value="trainingEinnahmen"
          /> -->
        </div>
        <div v-if="isJobtypWorkshop">
          <v-text-field
            v-model="workshopTeilnehmeranzahl"
            label="Teilnehmeranzahl"
            outlined
            type="number"
            :disabled="!isJobAbschliessbar"
            @change="workshopTeilnehmeranzahlChanged()"
          />
          <div
            v-if="job.ausstehenderBetrag"
            class="mb-4"
          >
            Es sind {{ numberToGerman(job.ausstehenderBetrag) }}€ ausstehend -
            Zahlungsart ist {{ job.zahlungsart }}
          </div>
          <v-currency-field
            v-model="workshopZahlungseingang"
            label="Zahlungseingang"
            suffix="€"
            outlined
            :disabled="!isJobAbschliessbar"
            @change="workshopZahlungseingangChanged()"
          />
        </div>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="notizen"
              outlined
              :disabled="!isJobAbschliessbar"
              @change="notizenInputChanged()"
            >
              <template #label>
                <div>
                  Notizen
                </div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <!-- <div
          v-for="jobTag in jobTage"
          :key="jobTag.id"
          class="d-flex justify-start"
        >
          {{ datumLeserlichKurz(jobTag.datum) }}
          <v-text-field
            label="Start"
            readonly
            :value="getJobTagZeiten(jobTag).startZeit"
            style="max-width: 80px;"
            class="ml-2"
            outlined
            :disabled="!isJobAbschliessbar"
            @click="showDialogTimePicker(jobTag,'startZeit')"
          />
          <v-text-field
            label="Ende"
            readonly
            :value="getJobTagZeiten(jobTag).endeZeit"
            style="max-width: 80px;"
            class="ml-2"
            outlined
            :disabled="!isJobAbschliessbar"
            @click="showDialogTimePicker(jobTag,'endeZeit')"
          />
          <v-text-field
            label="Pause"
            readonly
            :value="getJobTagZeiten(jobTag).pause"
            style="max-width: 80px;"
            class="ml-2"
            outlined
            :disabled="!isJobAbschliessbar"
            @click="showDialogTimePicker(jobTag,'pause')"
          />
        </div> -->

        <v-dialog
          ref="dialog"
          v-model="timePickerDialog"
          width="290px"
        >
          <v-time-picker
            v-if="timePickerDialog"
            v-model="timePickerValue"
            format="24hr"
          >
            <v-spacer />
            <v-btn
              color="primary"
              @click="timePickerDialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="primary"
              @click="changeJobTagZeiten()"
            >
              Ok
            </v-btn>
          </v-time-picker>
        </v-dialog>

        <v-btn
          v-if="!isJobAbgeschlossen"
          color="primary"
          :disabled="!isJobAbschliessbar || !isJobZeitlichAbschliessbar"
          @click="showJobAbschliessenDialog()"
        >
          Abschliessen
        </v-btn>
        <v-btn
          v-if="isJobAbgeschlossen"
          color="primary"
          disabled
        >
          Abgeschlossen
        </v-btn>
      </v-container>
    </v-card>

    <v-dialog
      v-model="jobAbschliessenDialog"
      max-width="400"
      width="calc(100vw - 40px)"
      max-height="700"
      height="calc(100dvh - 40px)"
    >
      <v-card>
        <v-card-title class="headline">
          Abschliessen
        </v-card-title>
        <v-card-text>
          Mit dem Abschluss bestätigst du alle Daten korrekt angegeben zu haben.
          Eingaben können nach dem Abschliessen nicht mehr geändert werden.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="jobAbschliessenDialog=false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="jobAbschliessen"
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import api from '@/api';
import formatMixin from '../formatMixin';

export default formatMixin.extend({
  mixins: [formatMixin],
  props: ['job'],
  data: () => ({
    notizen: null,
    timePickerDialog: false,
    timePickerValue: null,
    timePickerJobTagZeitSpalte: null,
    timePickerJobTag: null,
    workshopZahlungseingang: 0,
    workshopTeilnehmeranzahl: 0,
    jobAbschliessenDialog: false,
  }),
  mounted() {
    if (this.getJobDoc && this.getJobDoc.zahlungseingang) {
      this.workshopZahlungseingang = this.getJobDoc.zahlungseingang;
    }
    if (this.getJobDoc && this.getJobDoc.teilnehmerAnzahl) {
      this.workshopTeilnehmeranzahl = this.getJobDoc.teilnehmerAnzahl;
    } else if (this.job && this.job.teilnehmerAnzahl) {
      this.workshopTeilnehmeranzahl = this.job.teilnehmerAnzahl;
    }
    if (this.getJobDoc && this.getJobDoc.notizen) {
      this.notizen = this.getJobDoc.notizen;
    }
  },
  methods: {
    async showJobAbschliessenDialog() {
      try {
        const { uuid } = this.job;
        const docId = uuid;

        // falls workshop, setze teilnehmeranzahl falls nicht existiert
        if (this.isJobtypWorkshop && (!this.getJobDoc || !this.getJobDoc.teilnehmerAnzahl)) {
          this.$store.dispatch('setLoading', { state: false, message: 'Synchronisiere Lokal' });
          const teilnehmerAnzahl = this.workshopTeilnehmeranzahl;
          await this.$store.dispatch('syncStore/setJobTeilnehmeranzahl', {
            docId,
            teilnehmerAnzahl,
          });
        }
        // prüfe sync mit server
        // this.$store.dispatch('setLoading', { state: false, message: 'Prüfe Sync mit Server' });
        // const isDocInSync = await this.$store.dispatch('syncStore/isDocInSync', { docId });

        // if (!isDocInSync) {
        //   throw new Error('Lokale Daten sind nicht mit Server Daten synchronisiert');
        // } else {
        this.jobAbschliessenDialog = true;
        // }
      } catch (error) {
        EventBus.$emit('showSnackbar', { error: true, text: error });
      } finally {
        this.$store.dispatch('setLoading', { state: false });
      }
    },
    jobAbschliessen() {
      const { uuid } = this.job;
      // todo: anwesenheit fehlt anscheinend
      const data = {
        jobUuid: uuid,
        anmeldungen: this.getJobDoc?.teilnehmer ?? [],
        info: this.notizen,
        anmeldungenAnzahl: this.job.anmeldungen.length,
        teilnehmerAnzahl: this.trainingTeilnehmeranzahl,
      };

      this.$store.dispatch('setLoading', { state: true, message: 'Sende Abschluss' });
      api
        .postJobAbschliessen(data)
        .then((response) => {
          this.$store.dispatch('updateJobStatus', { uuid, status: 'Erledigt' });
          this.$store.dispatch('deleteZuweisung');
          this.$store.dispatch('syncErledigte');
          this.jobAbschliessenDialog = false;
          EventBus.$emit('showSnackbar', 'Job wurde abgeschlossen');
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
            EventBus.$emit('showSnackbar', { error: true, text: error.response.data.message });
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', { state: false });
        });
    },
    notizenInputChanged() {
      const docId = this.job.uuid;
      const { notizen } = this;
      this.$store.dispatch('syncStore/setJobNotizen', { docId, notizen });
    },
    workshopZahlungseingangChanged() {
      const docId = this.job.uuid;
      const zahlungseingang = this.workshopZahlungseingang;
      this.$store.dispatch('syncStore/setJobZahlungseingang', { docId, zahlungseingang });
    },
    workshopTeilnehmeranzahlChanged() {
      const docId = this.job.uuid;
      const teilnehmerAnzahl = this.workshopTeilnehmeranzahl;
      this.$store.dispatch('syncStore/setJobTeilnehmeranzahl', { docId, teilnehmerAnzahl });
    },
    refreshJobData(done: () => void) {
      const { uuid } = this.job;

      this.$store
        .dispatch('syncJobData', { uuid })
        .then(() => {
          EventBus.$emit('showSnackbar', 'Aktualisiert');
        })
        .catch((error) => {
          if (error && error.response) {
            EventBus.$emit('showSnackbar', error);
          }
        })
        .finally(() => done());
    },
    showDialogTimePicker(jobTag: any, spalte: any) {
      this.timePickerJobTag = jobTag;
      this.timePickerJobTagZeitSpalte = spalte;
      this.timePickerValue = this.getJobTagZeiten(jobTag)[spalte];
      this.timePickerDialog = true;
    },
    getJobTagZeiten(jobTag: any) {
      if (this.getJobDoc && this.getJobDoc.jobTage && this.getJobDoc.jobTage[jobTag.id]) {
        return this.getJobDoc.jobTage[jobTag.id];
      }
      return { startZeit: jobTag.startZeit, endeZeit: jobTag.endeZeit, pause: jobTag.pause };
    },
    changeJobTagZeiten() {
      const alteZeiten = this.getJobTagZeiten(this.timePickerJobTag);
      const zeiten = { ...alteZeiten };
      zeiten[this.timePickerJobTagZeitSpalte as any] = this.timePickerValue;
      const docId = this.job.uuid;
      const jobTagId = (this.timePickerJobTag as any).id;
      this.$store.dispatch('syncStore/setJobTagZeiten', { docId, jobTagId, zeiten });
      this.timePickerDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      getJobDoc: 'syncStore/getJobDoc',
    }),
    jobTage(): any[] {
      return this.job.jobTage;
    },
    trainingTeilnehmeranzahl(): number {
      let counter = 0;
      if (this.getJobDoc && this.getJobDoc.teilnehmer) {
        Object.keys(this.getJobDoc.teilnehmer).forEach((el) => {
          const anmeldung = this.getJobDoc.teilnehmer[el];
          if (anmeldung.anwesend && anmeldung.anwesend === true) {
            counter += 1;
          }
        });
      }
      return counter;
    },
    trainingEinnahmen(): number {
      let summe = 0;
      if (this.getJobDoc && this.getJobDoc.teilnehmer) {
        Object.keys(this.getJobDoc.teilnehmer).forEach((el) => {
          const anmeldung = this.getJobDoc.teilnehmer[el];
          if (anmeldung.betrag) {
            summe += anmeldung.betrag;
          }
        });
      }
      return summe;
    },
    isJobtypTraining(): boolean {
      return this.job.jobansichtTyp === 'Training';
    },
    isJobtypWorkshop(): boolean {
      return this.job.jobansichtTyp === 'Workshop';
    },
    isJobAbschliessbar(): boolean {
      return this.job.status === 'Vergeben';
    },
    isJobAbgeschlossen(): boolean {
      return this.job.status === 'Erledigt';
    },
    isJobZeitlichAbschliessbar(): boolean {
      return true; // todo: remove before release
      // let ersterTag: any = null;
      // let startZeit = '00:00';
      // this.job.jobTage.forEach((el: any) => {
      //   const jobTagDatum = new Date(el.datum);
      //   if (!ersterTag || jobTagDatum < ersterTag) {
      //     ersterTag = jobTagDatum;
      //     startZeit = el.startZeit;
      //   }
      // });
      // const jetzt = new Date();
      // const [stundenString, minutenString] = startZeit.split(':');
      // const stunden = Number.parseInt(stundenString, 10);
      // const minuten = Number.parseInt(minutenString, 10);
      // const jobDatumMitZeit = new Date(ersterTag);
      // jobDatumMitZeit.setHours(stunden, minuten, 0, 0);

      // return jetzt > jobDatumMitZeit;
    },
  },
});
</script>

<style>
</style>
