import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/HomeComponent.vue';
import Mitteilungen from '../views/MitteilungenComponent.vue';
import Jobangebote from '../views/Jobangebote/JobangeboteComponent.vue';
import Jobangeboteliste from '../views/Jobangebote/JobangebotelisteComponent.vue';
import MeineJobs from '../views/MeineJobs/MeineJobs.vue';
import MeineJobsÜbersicht from '../views/MeineJobs/MeineJobsÜbersicht.vue';
import MeineJobsBewerbungen from '../views/MeineJobs/Bewerbungen/MeineJobsBewerbungen.vue';
import MeineJobsBewerbungenJobansicht from '../views/MeineJobs/Bewerbungen/MeineJobsBewerbungenJobansicht.vue';
import MeineJobsBevorstehende from '../views/MeineJobs/Bevorstehende/MeineJobsBevorstehende.vue';
import MeineJobsBevorstehendeJobansicht from '../views/MeineJobs/Bevorstehende/MeineJobsBevorstehendeJobansicht.vue';
import MeineJobsDurchgeführte from '../views/MeineJobs/Durchgeführte/MeineJobsDurchgeführte.vue';
import MeineJobsDurchgeführteJobansicht from '../views/MeineJobs/Durchgeführte/MeineJobsDurchgeführteJobansicht.vue';
import Settings from '../views/Settings/SettingsComponent.vue';
import SettingsUebersicht from '../views/Settings/SettingsÜbersichtComponent.vue';
import Stammdaten from '../views/Settings/StammdatenComponent.vue';
import Organisationen from '../views/Settings/Organisationen.vue';
import Route1 from '../views/Route1Component.vue';
import Route2 from '../views/Route2Component.vue';
import JobangeboteJobansicht from '../views/Jobangebote/JobangeboteJobansicht.vue';
import Archiv from '../views/MeineJobs/Archiv/ArchivComponent.vue';
import Arbeitszeiten from '../views/MeineJobs/Arbeitszeiten/ArbeitszeitenComponent.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/mitteilungen',
    name: 'mitteilungen',
    component: Mitteilungen,
    children: [
      {
        path: 'route1',
        name: 'route1',
        component: Route1,
      },
      {
        path: 'route2',
        name: 'route2',
        component: Route2,
      },
    ],
  },
  {
    path: '/meine_jobs',
    component: MeineJobs,
    children: [
      {
        path: '',
        name: 'meine jobs',
        component: MeineJobsÜbersicht,
      },
      {
        path: 'bewerbungen',
        name: 'meine jobs - bewerbungen',
        component: MeineJobsBewerbungen,
        meta: { title: 'Bewerbungen' },
      },
      {
        path: 'bewerbungen/jobansicht',
        name: 'bewerbungen - jobansicht',
        component: MeineJobsBewerbungenJobansicht,
        meta: { title: 'Bewerbung' },
      },
      {
        path: 'bevorstehende',
        name: 'meine jobs - bevorstehende',
        component: MeineJobsBevorstehende,
        meta: { title: 'Bevorstehende' },
      },
      {
        path: 'bevorstehende/jobansicht',
        name: 'bevorstehende - jobansicht',
        component: MeineJobsBevorstehendeJobansicht,
        meta: { title: 'Bevorstehend' },
      },
      {
        path: 'durchgefuehrte',
        name: 'meine jobs - durchgefuehrte',
        component: MeineJobsDurchgeführte,
        meta: { title: 'Durchgeführte' },
      },
      {
        path: 'durchgefuehrte/jobansicht',
        name: 'durchgefuehrte - jobansicht',
        component: MeineJobsDurchgeführteJobansicht,
        meta: { title: 'Durchgeführt' },
      },
      {
        path: 'archiv',
        name: 'archiv',
        component: Archiv,
        meta: { title: 'Archiv' },
      },
      {
        path: 'arbeitszeiten',
        name: 'arbeitszeiten',
        component: Arbeitszeiten,
        meta: { title: 'Arbeitszeiten' },
      },
    ],
  },
  {
    path: '/jobangebote',
    component: Jobangebote,
    children: [
      {
        path: '',
        name: 'jobangebote',
        component: Jobangeboteliste,
      },
      {
        path: 'jobansicht',
        name: 'jobangebote - jobansicht',
        component: JobangeboteJobansicht,
        meta: { title: 'Jobangebot' },
      },
    ],
  },
  {
    path: '/settings',
    component: Settings,
    children: [
      {
        path: '',
        name: 'settings',
        component: SettingsUebersicht,
      },
      {
        path: 'stammdaten',
        name: 'stammdaten',
        component: Stammdaten,
      },
      {
        path: 'organisationen',
        name: 'organisationen',
        component: Organisationen,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   if (store.getters.isAuthenticated && !store.getters.authorization) {
//     await store.dispatch('syncAuthorization');
//   }

//   if (!store.getters.isAuthenticated && to.name !== 'home') {
//     return next({ name: 'home' });
//   } if (store.getters.isAuthenticated && to.name !== 'home') {
//   // noch irgendwo ungültige routes abfangen du musst
//     if (from.path) {
//       if (from.path.startsWith('/mitteilungen')) {
//         store.dispatch('setLastMitteilungenRoute', { path: from.path });
//       } else if (from.path.startsWith('/meine_jobs')) {
//         store.dispatch('setLastMeineJobsRoute', { path: from.path });
//       } else if (from.path.startsWith('/jobangebote')) {
//         store.dispatch('setLastJobangeboteRoute', { path: from.path });
//       } else if (from.path.startsWith('/settings')) {
//         store.dispatch('setLastSettingsRoute', { path: from.path });
//       }
//     }

//     return next();
//   } if (store.getters.isAuthenticated && to.name === 'home') {
//     return next({ name: 'mitteilungen' });
//   }
//   next();
// });

export default router;
